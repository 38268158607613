import { API_URL } from '../../../constants';
import { BrandCode } from '../../../shared/model/brand.model';

const ASSESSMENTS_PATH = `${API_URL}/assessments`;
const STORES_PATH = `${API_URL}/stores`;
const ASSESSMENTS_ARCHIVE_PATH = `${API_URL}/archived-assessments`;
const ASSESSMENTS_FOLLOWUP_PATH = `${API_URL}/followed-up-assessments`;

const paths = {
  health: (): string => `${API_URL}/health`,
  getAssessments: (): string => `${ASSESSMENTS_PATH}`,
  getAssessment: (id: string): string => `${ASSESSMENTS_PATH}/${id}`,
  getAvailableStores: (): string => `${STORES_PATH}`,
  getAuditors: (brand: BrandCode): string => `${ASSESSMENTS_PATH}/auditors?brand=${brand}`,
  updateAuditors: (id: string): string => `${ASSESSMENTS_PATH}/${id}/auditors`,
  updateYearAndMonth: (id: string): string => `${ASSESSMENTS_PATH}/${id}/year-month`,
  updateDistributionList: (id: string): string => `${ASSESSMENTS_PATH}/${id}/distribution-list`,
  updateReadOnlyDistributionList: (id: string): string => `${ASSESSMENTS_PATH}/${id}/read-only-distribution-list`,
  saveComment: (id: string): string => `${ASSESSMENTS_PATH}/${id}/notes`,
  updateOverview: (id: string): string => `${ASSESSMENTS_PATH}/${id}/overview`,
  updateStatus: (id: string): string => `${ASSESSMENTS_PATH}/${id}/status-changes`,
  updateStoreManagerSince: (id: string): string => `${ASSESSMENTS_PATH}/${id}/store-manager-since`,
  updateStoreManagerNotAvailable: (id: string): string => `${ASSESSMENTS_PATH}/${id}/store-manager-not-available`,
  updateRetailProcedure: (id: string): string => `${ASSESSMENTS_PATH}/${id}/retail-procedure`,
  updateEntityAudit: (id: string): string => `${ASSESSMENTS_PATH}/${id}/entity-audit`,
  updateStoreManager: (id: string): string => `${ASSESSMENTS_PATH}/${id}/store-manager`,
  updateStoreManagerAssistant: (id: string): string => `${ASSESSMENTS_PATH}/${id}/store-manager-assistant`,
  updateRetailAreaManager: (id: string): string => `${ASSESSMENTS_PATH}/${id}/retail-area-manager`,
  updateBrandCoordinators: (id: string): string => `${ASSESSMENTS_PATH}/${id}/brand-coordinator-list`,
  updateEmployees: (id: string): string => `${ASSESSMENTS_PATH}/${id}/store-employees`,
  saveAuditingResult: (id: string): string => `${ASSESSMENTS_PATH}/${id}/auditor-results`,
  saveSubProcessResult: (id: string): string => `${ASSESSMENTS_PATH}/${id}/result`,
  saveSubProcessManagerNoteRequest: (id: string): string => `${ASSESSMENTS_PATH}/${id}/result-manager-note`,
  saveSubProcessRegionalManagerNoteRequest: (id: string): string =>
    `${ASSESSMENTS_PATH}/${id}/result-regional-manager-note`,
  getAuditorResults: (id: string): string => `${ASSESSMENTS_PATH}/${id}/auditor-results`,
  uploadPictures: (): string => `${API_URL}/pictures`,
  uploadAttachments: (): string => `${API_URL}/attachments`,
  getPicture: (id: string): string => `${API_URL}/pictures/${id}`,
  getAttachment: (id: string): string => `${API_URL}/attachments/${id}`,
  getPictureThumbnail: (id: string): string => `${API_URL}/pictures/${id}/thumbnail`,
  saveActionPlan: (id: string): string => `${ASSESSMENTS_PATH}/${id}/action-plan`,
  submitActionPlan: (id: string): string => `${ASSESSMENTS_PATH}/${id}/action-plan/submitted`,
  saveActionPlanAttachmentsChecked: (id: string): string => `${ASSESSMENTS_PATH}/${id}/action-plan/attachments-checked`,
  saveFollowUpAttachmentsChecked: (id: string): string => `${ASSESSMENTS_FOLLOWUP_PATH}/${id}/attachments-checked`,
  saveActionPlanAuditorNote: (id: string): string => `${ASSESSMENTS_PATH}/${id}/action-plan-auditor-note`,
  saveActionPlanBrandNote: (id: string): string => `${ASSESSMENTS_PATH}/${id}/action-plan-brand-note`,
  saveActionPlanEditor: (id: string): string => `${ASSESSMENTS_PATH}/${id}/action-plan-editor`,
  createTopicForDiscussion: (assessmentId: string): string =>
    `${ASSESSMENTS_PATH}/${assessmentId}/topics-for-discussion`,
  editTopicForDiscussion: (assessmentId: string, topicForDiscussionId: string): string =>
    `${ASSESSMENTS_PATH}/${assessmentId}/topics-for-discussion/${topicForDiscussionId}/title`,
  deleteTopicForDiscussion: (assessmentId: string, topicForDiscussionId: string): string =>
    `${ASSESSMENTS_PATH}/${assessmentId}/topics-for-discussion/${topicForDiscussionId}`,
  createTopicComment: (assessmentId: string, topicForDiscussionId: string): string =>
    `${ASSESSMENTS_PATH}/${assessmentId}/topics-for-discussion/${topicForDiscussionId}/comments`,
  updateTopicComment: (assessmentId: string, topicForDiscussionId: string, commentId: string): string =>
    `${ASSESSMENTS_PATH}/${assessmentId}/topics-for-discussion/${topicForDiscussionId}/comments/${commentId}`,
  getAssessmentsArchive: (): string => `${ASSESSMENTS_ARCHIVE_PATH}`,
  getArchiveStatuses: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/statuses`,
  getArchiveBrands: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/brands`,
  getArchiveRegions: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/regions`,
  getArchiveCountries: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/countries`,
  getArchiveCities: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/cities`,
  getArchiveStores: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/stores`,
  getArchiveAuditors: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/auditors`,
  getArchiveBrandCoordinators: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/brand-coordinators`,
  getArchiveDistributionListMembers: (): string => `${ASSESSMENTS_ARCHIVE_PATH}/distribution-list-members`,
  getArchiveReadOnlyDistributionListMembers: (): string =>
    `${ASSESSMENTS_ARCHIVE_PATH}/read-only-distribution-list-members`,
  getAssessmentsFollowUp: (): string => `${ASSESSMENTS_FOLLOWUP_PATH}`,
  updateFollowUpStatus: (id: string): string => `${ASSESSMENTS_FOLLOWUP_PATH}/${id}/status-changes`,
  saveFollowUp: (id: string): string => `${ASSESSMENTS_FOLLOWUP_PATH}/${id}`,
  submitFollowUp: (id: string): string => `${ASSESSMENTS_FOLLOWUP_PATH}/${id}/submitted`,
};

export default paths;
