/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Brand } from '../../../shared/model/brand.model';
import { ModalMultiSelectField, SelectItem } from '../../../shared/ModalMultiSelectField';
import { useAppDispatch } from '../../../core/store';
import { fetchBrands, selectBrands, selectIsFetching } from '../store/assessmentBulkUpdateSlice';
import { AssessmentBulkUpdateFilters } from '../model/assessmentBulkUpdateQueryParams';

interface BrandMultiSelectFieldProps {
  filters: AssessmentBulkUpdateFilters;
  defaultValue?: Brand[];
  editable?: boolean;
  onUpdate?: (brand: Brand[]) => void;
}

export function BrandMultiSelectField({
  filters,
  defaultValue = [],
  editable = false,
  onUpdate,
}: BrandMultiSelectFieldProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      dispatch(fetchBrands(filters));
    }
  }, [dispatch, editable, filters]);

  const brandList = useSelector(selectBrands);
  const isFetching = useSelector(selectIsFetching);

  const toSelectItem = (item: Brand): SelectItem => {
    return {
      code: item.code,
      description: item.description,
      logo: item.logo,
    };
  };

  const toBrand = (item: SelectItem): Brand => {
    return {
      code: item.code,
      description: item.description,
      logo: item.logo || '',
    };
  };

  return (
    <ModalMultiSelectField
      icon='copyright'
      defaultValue={defaultValue != null ? defaultValue.map(v => toSelectItem(v)) : []}
      loading={isFetching}
      list={brandList.map((b: any): SelectItem => toSelectItem(b) as SelectItem)}
      title={t('assessment.bulkUpdate.filters.brands') || ''}
      editable={editable}
      onUpdateValue={(selectedBrand: SelectItem[]) => {
        if (onUpdate) {
          onUpdate(selectedBrand.map(i => toBrand(i)));
        }
      }}
    />
  );
}
