/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Assessment, Auditor, BrandCoordinator } from '../model/assessment';
import { Store } from '../../../shared/model/store.model';
import api from '../utils/api';
import { DEFAULT_CACHE_TTL } from '../../../constants';
import { checkValueInclude } from '../../../shared/utils';
import {
  AssessmentArchiveFilters,
  covertFiltersInQueryParams,
  emptyAssessmentArchiveFilters,
} from '../model/assessmentArchiveQueryParams';
import { Brand } from '../../../shared/model/brand.model';
import type { AppThunk, RootState } from '../../../core/store';
import { Contact } from '../../users/model/user.model';

interface AssessmentsArchiveSliceState {
  data: Assessment[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
  filter: string;
  filters: AssessmentArchiveFilters;
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  stores: Store[];
  auditors: Auditor[];
  brandCoordinators: BrandCoordinator[];
  distributionListMembers: Contact[];
  readOnlyDistributionListMembers: Contact[];
  showResults: boolean;
}

const initialState: AssessmentsArchiveSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
  filter: '',
  filters: emptyAssessmentArchiveFilters,
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  stores: [],
  auditors: [],
  brandCoordinators: [],
  distributionListMembers: [],
  readOnlyDistributionListMembers: [],
  showResults: false,
};

const assessmentsArchiveSlice = createSlice({
  name: 'assessmentsArchive',
  initialState,
  reducers: {
    startFetch: (state: Draft<AssessmentsArchiveSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<Assessment[]>) => ({
      ...state,
      isFetching: false,
      data: [...action.payload],
      error: '',
      isValidCache: true,
    }),
    clearAssessments: (state: Draft<AssessmentsArchiveSliceState>) => ({
      ...state,
      isFetching: false,
      data: [],
      error: '',
      isValidCache: true,
    }),
    httpError: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    invalidateCache: (state: Draft<AssessmentsArchiveSliceState>) => ({ ...state, isValidCache: false }),

    changeFilter: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<string>) => ({
      ...state,
      filter: action.payload,
    }),
    setFilters: (state: Draft<AssessmentsArchiveSliceState>, { payload }: PayloadAction<AssessmentArchiveFilters>) => ({
      ...state,
      filters: payload,
    }),
    setShowResults: (state: Draft<AssessmentsArchiveSliceState>, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showResults: payload,
    }),
    finishBrandFetch: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<Brand[]>) => ({
      ...state,
      isFetching: false,
      brands: [...action.payload],
      error: '',
    }),
    finishRegionFetch: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      regions: [...action.payload],
      error: '',
    }),
    finishCountriesFetch: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      countries: [...action.payload],
      error: '',
    }),
    finishCitiesFetch: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      cities: [...action.payload],
      error: '',
    }),
    finishStoresFetch: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<Store[]>) => ({
      ...state,
      isFetching: false,
      stores: [...action.payload],
      error: '',
    }),
    finishAuditorsFetch: (state: Draft<AssessmentsArchiveSliceState>, action: PayloadAction<Auditor[]>) => ({
      ...state,
      isFetching: false,
      auditors: [...action.payload],
      error: '',
    }),
    finishBrandCoordinatorsFetch: (
      state: Draft<AssessmentsArchiveSliceState>,
      action: PayloadAction<BrandCoordinator[]>
    ) => ({
      ...state,
      isFetching: false,
      brandCoordinators: [...action.payload],
      error: '',
    }),
    finishDistributionListMembersFetch: (
      state: Draft<AssessmentsArchiveSliceState>,
      action: PayloadAction<Contact[]>
    ) => ({
      ...state,
      isFetching: false,
      distributionListMembers: [...action.payload],
      error: '',
    }),
    finishReadOnlyDistributionListMembersFetch: (
      state: Draft<AssessmentsArchiveSliceState>,
      action: PayloadAction<Contact[]>
    ) => ({
      ...state,
      isFetching: false,
      readOnlyDistributionListMembers: [...action.payload],
      error: '',
    }),
  },
});

export const {
  startFetch,
  finishFetch,
  clearAssessments,
  httpError,
  invalidateCache,
  changeFilter,
  setFilters,
  setShowResults,
  finishBrandFetch,
  finishRegionFetch,
  finishCountriesFetch,
  finishCitiesFetch,
  finishStoresFetch,
  finishAuditorsFetch,
  finishBrandCoordinatorsFetch,
  finishDistributionListMembersFetch,
  finishReadOnlyDistributionListMembersFetch,
} = assessmentsArchiveSlice.actions;

export const fetchAssessmentsArchive = (filters: AssessmentArchiveFilters) => async (dispatch: any, state: any) => {
  const { isValidCache } = state().assessmentsArchive;
  if (!isValidCache) {
    dispatch(clearAssessments());
    dispatch(startFetch());
    try {
      const assessments = await api.getAssessmentsArchive(covertFiltersInQueryParams(filters));
      dispatch(finishFetch(assessments));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
    setTimeout(() => dispatch(invalidateCache()), DEFAULT_CACHE_TTL);
  }
};

export const fetchBrands =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const brands: Brand[] = await api.getArchiveBrands(covertFiltersInQueryParams(filters));
      dispatch(finishBrandFetch(brands));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchRegions =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const regions: string[] = await api.getArchiveRegions(covertFiltersInQueryParams(filters));
      dispatch(finishRegionFetch(regions));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchCountries =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const countries: string[] = await api.getArchiveCountries(covertFiltersInQueryParams(filters));
      dispatch(finishCountriesFetch(countries));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchCities =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const cities: string[] = await api.getArchiveCities(covertFiltersInQueryParams(filters));
      dispatch(finishCitiesFetch(cities));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchStores =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const stores: Store[] = await api.getArchiveStores(covertFiltersInQueryParams(filters));
      dispatch(finishStoresFetch(stores));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchAuditors =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const auditors: Auditor[] = await api.getArchiveAuditors(covertFiltersInQueryParams(filters));
      dispatch(finishAuditorsFetch(auditors));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchBrandCoordinators =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const brandCoordinators: BrandCoordinator[] = await api.getArchiveBrandCoordinators(
        covertFiltersInQueryParams(filters)
      );
      dispatch(finishBrandCoordinatorsFetch(brandCoordinators));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchDistributionListMembers =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const distributionListMembers: Contact[] = await api.getArchiveDistributionListMembers(
        covertFiltersInQueryParams(filters)
      );
      dispatch(finishDistributionListMembersFetch(distributionListMembers));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchReadOnlyDistributionListMembers =
  (filters: AssessmentArchiveFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const readOnlyDistributionListMembers: Contact[] = await api.getArchiveReadOnlyDistributionListMembers(
        covertFiltersInQueryParams(filters)
      );
      dispatch(finishReadOnlyDistributionListMembersFetch(readOnlyDistributionListMembers));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectAssessments = (state: any): Assessment[] => state.assessmentsArchive.data;
export const selectIsFetching = (state: any): boolean => state.assessmentsArchive.isFetching;

export const selectSelectedStore = (state: any): Store | null => state.assessmentsArchive.store;

export const selectAssessmentsFilter = (state: any): string => state.assessmentsArchive.filter;

export const selectFilteredAssessmentsArchive = createSelector(
  selectAssessments,
  selectAssessmentsFilter,
  (assessments, filter) =>
    assessments.filter(
      (a: Assessment) =>
        checkValueInclude(a.code, filter) ||
        checkValueInclude(a.store.hfmCode, filter) ||
        checkValueInclude(a.store.brand.description, filter) ||
        checkValueInclude(a.store.keringRegion, filter) ||
        checkValueInclude(a.store.city, filter) ||
        checkValueInclude(a.store.name, filter) ||
        a.auditors.some(auditor => checkValueInclude(auditor.email, filter)) ||
        a.auditors.some(auditor => checkValueInclude(auditor.name, filter))
    )
);

export const selectFilters = (state: RootState) => state.assessmentsArchive.filters;
export const selectBrands = (state: RootState) => state.assessmentsArchive.brands;
export const selectRegions = (state: RootState) => state.assessmentsArchive.regions;
export const selectCountries = (state: RootState) => state.assessmentsArchive.countries;
export const selectCities = (state: RootState) => state.assessmentsArchive.cities;
export const selectStores = (state: RootState) => state.assessmentsArchive.stores;
export const selectAuditors = (state: RootState) => state.assessmentsArchive.auditors;
export const selectBrandCoordinators = (state: RootState) => state.assessmentsArchive.brandCoordinators;
export const selectDistributionListMembers = (state: RootState) => state.assessmentsArchive.distributionListMembers;
export const selectReadOnlyDistributionListMembers = (state: RootState) =>
  state.assessmentsArchive.readOnlyDistributionListMembers;
export const selectShowResults = (state: RootState) => state.assessmentsArchive.showResults;

export default assessmentsArchiveSlice.reducer;
