import { css } from '@emotion/react';
import globalStyle from '../../../shared/global.style';

const footer = css`
  display: flex;
  margin-top: auto;
  background-color: var(--saa-white-color);
  color: var(--saa-primary-color);
  padding: 10px 10px 5px;
  min-height: 60px;
  flex-shrink: 0;
`;

const button = css`
  &&& {
    flex: 1;
    color: var(--saa-white-color);
    background-color: var(--saa-primary-color);
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    min-width: 160px;
    max-width: min(250px, 100%);
    min-height: 46px;
    margin-bottom: 5px;
    &&:hover,
    &&:focus {
      color: var(--saa-primary-color-40);
      background-color: var(--saa-primary-color);
    }
  }
`;

const buttonContainer = css`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 50%;
`;

const buttonLeftContainer = css`
  &&&& {
    ${buttonContainer};
    justify-content: flex-start;
  }
`;

const buttonRightContainer = css`
  &&&& {
    ${buttonContainer};
    justify-content: flex-end;
  }
`;

const modalConfirm = css`
  ${globalStyle.modalConfirm}
`;

const confirmButton = css`
  ${globalStyle.confirmButton}
`;

const warningConfirmHeader = css`
  color: var(--saa-error);
  padding: 14px;
  border-bottom: 1px solid var(--saa-primary-color-20);
`;

export default {
  footer,
  button,
  modalConfirm,
  confirmButton,
  warningConfirmHeader,
  buttonLeftContainer,
  buttonRightContainer,
};
