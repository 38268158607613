/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { ListChildComponentProps, VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { AssessmentCard } from '../components/AssessmentCard';
import {
  fetchAssessments,
  selectAssessments,
  selectGroupedAssessments,
  selectIsFetchingAssessments,
  selectIsOpen,
} from '../store/assessmentsSlice';
import style from './assessments.style';
import { STATUS, StatusCode } from '../model/assessmentStatus';
import { change, selectAssessmentsFilter } from '../store/filterSlice';
import { onCloseCreation, selectIsCreating, startCreation } from '../store/onCreationSlice';
import { AssessmentCreation } from '../components/AssessmentCreation';
import { ModalPage } from '../../../shared/ModalPage';
import { SearchBox } from '../../../shared/SearchBox';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { AUDITOR_STAFF_ROLES, checkRoles } from '../../auth/model/principal.model';
import { useAppDispatch } from '../../../core/store';
import { Assessment } from '../model/assessment';
import { getDate, useWindowSize } from '../../../core/utils';
import { AssessmentFilter, FiltersBadgesContainer } from '../components/assessmentFilter/AssessmentFilter';

interface ListItem {
  type: string;
  assessments: Assessment[];
  status?: StatusCode;
}

interface AssessmentsRowProps {
  assessments: Assessment[];
  itemsPerRow: number;
}

const AssessmentsRow = ({ assessments, itemsPerRow }: AssessmentsRowProps): JSX.Element => {
  const CARD_WIDTH = 350;

  return itemsPerRow > 1 ? (
    <div css={style.cardsRowContainer}>
      {assessments.map(a => (
        <AssessmentCard key={a.id} assessment={a} />
      ))}
    </div>
  ) : (
    <AutoSizer>
      {({ height, width }: any) => {
        return (
          <List
            height={height}
            itemCount={assessments.length}
            itemSize={() => CARD_WIDTH}
            width={width}
            overscanCount={10}
            layout='horizontal'>
            {(rowProps: ListChildComponentProps) => {
              const a = assessments[rowProps.index];
              return (
                <div style={rowProps.style}>
                  <AssessmentCard key={a.id} assessment={a} />
                </div>
              );
            }}
          </List>
        );
      }}
    </AutoSizer>
  );
};
export const Assessments = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const size = useWindowSize();

  const CARD_HEIGHT = 190;
  const CARD_WIDTH = 350;
  const STATUS_HEIGHT = 46;

  const assessmentsList = useSelector(selectAssessments);
  const assessmentsGroupedByStatus = useSelector(selectGroupedAssessments);
  const isFetching = useSelector(selectIsFetchingAssessments);
  const isCreating = useSelector(selectIsCreating);
  const isFilterOpen = useSelector(selectIsOpen);
  const assessmentsListRef = useRef<List>(null);

  const statusList = useMemo(() => Object.keys(assessmentsGroupedByStatus), [assessmentsGroupedByStatus]);

  /*
  const filterItems = (data: Assessment[], filter: AssessmentFilterInterface): Assessment[] => {
    return data.filter(item => {
      const isStatusMatch = filter.status.length === 0 || filter.status.includes(item.status.code);
      const isBrandMatch = filter.brands.length === 0 || filter.brands.map(b => b.code).includes(item.store.brand.code);
      const isRegionMatch = filter.regions.length === 0 || filter.regions.includes(item.store.keringRegion);
      const isCountryMatch =
        filter.countries.length === 0 || filter.countries.includes(item.store.keringCountry.description);
      const isAuditorMatch =
        filter.auditors.length === 0 ||
        item.auditors.map(a => a.email).some(auditor => filter.auditors.map(a => a.email).includes(auditor));
      const isMonthMatch =
        (filter.monthFrom === undefined || item.month >= filter.monthFrom) &&
        (filter.monthTo === undefined || item.month <= filter.monthTo);
      const isYearMatch =
        (filter.yearFrom === undefined || item.year >= filter.yearFrom) &&
        (filter.yearTo === undefined || item.year <= filter.yearTo);

      return (
        isStatusMatch &&
        isBrandMatch &&
        isRegionMatch &&
        isCountryMatch &&
        isAuditorMatch &&
        isMonthMatch &&
        isYearMatch
      );
    });
  };
  */

  useEffect(() => {
    setTimeout(() => {
      if (assessmentsListRef.current != null) {
        assessmentsListRef.current.resetAfterIndex(0);
      }
    }, 0);
  }, [size, assessmentsGroupedByStatus]);

  const splitArray = useCallback((assessments: Assessment[], itemsPerRow: number) => {
    const ret = [];
    for (let i = 0; i < assessments.length; i += itemsPerRow) {
      ret.push(assessments.slice(i, i + itemsPerRow));
    }
    return ret;
  }, []);

  const getListItems = useCallback(
    (itemsPerRow: number): ListItem[] =>
      statusList.flatMap(key => {
        const slittedArray =
          itemsPerRow === 1
            ? [assessmentsGroupedByStatus[key as StatusCode]]
            : splitArray(assessmentsGroupedByStatus[key as StatusCode], itemsPerRow);

        const cardRows = slittedArray.map(assessments => ({
          type: 'assessment',
          assessments,
        }));

        if (cardRows.length > 0) {
          return [{ type: 'status', status: key as StatusCode, assessments: [] }, ...cardRows];
        }

        return [];
      }),
    [assessmentsGroupedByStatus, splitArray, statusList]
  );

  useEffect(() => {
    dispatch(fetchAssessments());
  }, [dispatch]);

  const getItemSize = useCallback((index: number, items: ListItem[]) => {
    const element = items[index];
    return element.type === 'status' ? STATUS_HEIGHT : CARD_HEIGHT;
  }, []);

  return (
    <div css={style.container}>
      {isFetching && !isCreating ? (
        <Loader active />
      ) : isFilterOpen ? (
        <AssessmentFilter items={assessmentsList} />
      ) : (
        <>
          <Header />
          <div css={style.cardsContainer}>
            <AutoSizer>
              {({ height, width }: any) => {
                const itemsPerRow = Math.floor(width / CARD_WIDTH);
                const items = getListItems(itemsPerRow);

                return (
                  <List
                    ref={assessmentsListRef}
                    height={height}
                    width={width}
                    itemCount={items.length}
                    itemSize={index => getItemSize(index, items)}
                    overscanCount={10}>
                    {(rowProps: ListChildComponentProps) => {
                      const item = items[rowProps.index];
                      return (
                        <div style={rowProps.style}>
                          {item.type === 'status' ? (
                            <h5 css={style.sectionTitle(item.status as StatusCode)}>
                              {t(`assessment.status.${item.status}`)}
                              {item.status === STATUS.CLOSED.code ? ` (${getDate().getFullYear()})` : ''}
                            </h5>
                          ) : (
                            <AssessmentsRow assessments={item.assessments} itemsPerRow={itemsPerRow} />
                          )}
                        </div>
                      );
                    }}
                  </List>
                );
              }}
            </AutoSizer>
          </div>
        </>
      )}
    </div>
  );
};

const Header = (): JSX.Element => {
  const { t } = useTranslation();
  const creation = useSelector(selectIsCreating);
  const user = useSelector(selectPrincipal);
  const isAuditor = useCallback(() => checkRoles(user, AUDITOR_STAFF_ROLES), [user]);
  const dispatch = useAppDispatch();
  const dispatchCreation = useCallback(() => dispatch(startCreation()), [dispatch]);
  const closeCreation = useCallback(() => {
    dispatch(onCloseCreation());
  }, [dispatch]);

  return (
    <div css={style.header}>
      {isAuditor() && <Button onClick={dispatchCreation} icon='plus' css={style.addButton} />}
      <SearchAssessmentBox />
      {creation ? (
        <ModalPage onClose={closeCreation} title={t('assessment.creation.title') || ''}>
          <AssessmentCreation />
        </ModalPage>
      ) : (
        <></>
      )}
    </div>
  );
};

const SearchAssessmentBox = (): JSX.Element => {
  const filter = useSelector(selectAssessmentsFilter, () => true);
  const dispatch = useAppDispatch();
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(change(value));
  }, 500);

  return (
    <div css={style.searchBoxContainer}>
      <FiltersBadgesContainer />
      <SearchBox defaultValue={filter} onChange={value => debounced(value)} />
    </div>
  );
};
