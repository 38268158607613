import { css, SerializedStyles } from '@emotion/react';
import { StatusCode } from '../model/assessmentStatus';
import style from '../../../shared/global.style';

const container = css`
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const group = css`
  display: flex;
  overflow: auto;
  @media (min-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const sectionTitle = (status: StatusCode): SerializedStyles => {
  const border = `&& {box-shadow: inset 0 -2px 0 0 ${style.statusColor(status)}}`;
  return css`
    && {
      margin: 10px 0 10px;
      padding-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${border};
    }
  `;
};

const header = css`
  display: flex;
  @media (min-width: 1200px) {
    margin-bottom: 5px;
  }
`;

const searchBoxContainer = css`
  flex: 1;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  height: max-content;
`;

const cardsContainer = css`
  flex: 1;
`;

const addButton = css`
  && {
    position: absolute;
    z-index: 10;
    bottom: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background-color: var(--saa-primary-color-80);
    color: var(--saa-white-color);
  }
  @media (min-width: 1200px) {
    &&& {
      position: unset;
      z-index: unset;
      bottom: unset;
      right: unset;
      width: unset;
      height: 40px;
      border-radius: unset;
      background-color: var(--saa-primary-color-80);
      color: var(--saa-white-color);
      :before {
        content: 'Create assessment';
        padding-right: 5px;
      }
    }
  }
`;

const dimmer = css`
  && .content {
    width: 100%;
    height: 100%;
  }
`;

const cardsRowContainer = css`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
`;

export default {
  group,
  container,
  sectionTitle,
  addButton,
  cardsContainer,
  header,
  dimmer,
  searchBoxContainer,
  cardsRowContainer,
};
