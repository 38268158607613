import ReactPDF, { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';
import { ACTION_PLAN_STATUS, AssessmentDetail, Priority, ResultStatus } from '../model/assessmentDetail.model';
import arialUnicodeFont from '../../../theme/fonts/arial-unicode-ms.ttf';
import { getDateString } from '../../../core/utils';
import { STATUS } from '../model/assessmentStatus';

interface FindingsPdfDocumentProps {
  assessmentDetail: AssessmentDetail;
  userName: string;
}

Font.register({
  family: 'Arial Unicode',
  format: 'truetype',
  src: arialUnicodeFont,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Arial Unicode',
  },
  row: {
    flexDirection: 'row',
  },
  rowHeader: {
    flexDirection: 'row',
    // --saa-primary-color-20
    backgroundColor: '#dbdde0',
  },
  cell: {
    width: 250,
    fontSize: 4,
    borderLeft: 0.5,
    borderBottom: 0.5,
    padding: 4,
    color: '#25282b',
    borderColor: '#000000',
  },
  bigCell: {
    width: 500,
    fontSize: 4,
    borderLeft: 0.5,
    borderBottom: 0.5,
    padding: 4,
    // --saa-primary-color
    color: '#25282b',
    borderColor: '#000000',
  },
  smallCell: {
    width: 125,
    fontSize: 4,
    borderLeft: 0.5,
    borderBottom: 0.5,
    padding: 4,
    // --saa-primary-color
    color: '#25282b',
    borderColor: '#000000',
  },
  extraSmallCell: {
    width: 100,
    fontSize: 4,
    borderLeft: 0.5,
    borderBottom: 0.5,
    padding: 4,
    // --saa-primary-color
    color: '#25282b',
    borderColor: '#000000',
  },
  topicTitleCell: {
    width: 250,
    fontSize: 4,
    borderLeft: 0.5,
    padding: 4,
    color: '#25282b',
    borderColor: '#000000',
  },
  header: {
    flexDirection: 'row',
    padding: 5,
    alignItems: 'center',
    // --saa-primary-color
    backgroundColor: '#25282b',
  },
  brandImage: {
    borderRadius: 20,
    width: 40,
    height: 40,
    marginRight: 5,
  },
  title: {
    fontSize: 10,
    color: '#ffffff',
  },
  subTitle: {
    fontSize: 8,
    // --saa-primary-color-60
    color: '#a0a4a8',
  },
  signature: {
    fontSize: 8,
    // --saa-primary-color-60
    color: '#a0a4a8',
    marginLeft: 'auto',
    flexDirection: 'column',
    paddingRight: 5,
  },
});

const showTopics = (assessment: AssessmentDetail): boolean => {
  return (
    assessment.audit.topicsForDiscussion.some(topic => topic.comments.length !== 0) ||
    assessment.status?.code !== STATUS.SENT_TO_THE_STORES.code
  );
};

export const FindingsPdfDocument = ({
  assessmentDetail,
  userName,
}: FindingsPdfDocumentProps): ReactElement<ReactPDF.Document> => (
  <Document>
    <Page size='A4' orientation='landscape' style={styles.page} wrap>
      <View style={styles.header}>
        <Image src={assessmentDetail.store.brand.logo} style={styles.brandImage} />
        <View>
          <Text style={styles.title}>{assessmentDetail.store.name}</Text>
          <Text style={styles.subTitle}>{assessmentDetail.code}</Text>
        </View>
        <View style={styles.signature}>
          <Text>
            Downloaded by {userName} on {getDateString()}
          </Text>
        </View>
      </View>
      <View style={styles.rowHeader} fixed>
        <Text style={[styles.cell, { fontSize: 5 }]}>Process</Text>
        <Text style={[styles.cell, { fontSize: 5 }]}>Sub-Process</Text>
        <Text style={[styles.bigCell, { fontSize: 5 }]}>Finding</Text>
        <Text style={[styles.smallCell, { fontSize: 5 }]}>Priority</Text>
        <Text style={[styles.cell, { fontSize: 5 }]}>Key Business Risk</Text>
        <Text style={[styles.bigCell, { fontSize: 5 }]}>Recommendation</Text>
        <Text style={[styles.bigCell, { fontSize: 5 }]}>Action Plan</Text>
        <Text style={[styles.extraSmallCell, { fontSize: 5 }]}>Due date</Text>
        <Text style={[styles.cell, { fontSize: 5 }]}>Supervisor</Text>
      </View>
      {assessmentDetail.audit.processes
        .flatMap(p => p.subProcesses)
        // siamo dopo il merge, il result è sempre presente
        .filter(sp => sp.result.status === ResultStatus.FAILED)
        .map((sp, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <View style={styles.row} wrap={false} key={`${sp.code}-${index}`}>
            <Text style={styles.cell}>{`${sp.processCode} - ${sp.processDescription}`}</Text>
            <Text style={styles.cell}>{sp.description}</Text>
            <Text style={styles.bigCell}>{sp.result != null ? sp.result.finding : ''}</Text>
            <Text
              style={[
                styles.smallCell,
                {
                  backgroundColor:
                    //  --saa-priority-high
                    sp.result.priority === Priority.HIGH
                      ? '#ff5050'
                      : // --saa-priority-medium
                      sp.result.priority === Priority.MEDIUM
                      ? '#ffff75'
                      : // --saa-priority-significant:
                      sp.result.priority === Priority.SIGNIFICANT
                      ? '#ffc000'
                      : //  --saa-priority-low
                      sp.result.priority === Priority.LOW
                      ? '#5ac66c'
                      : '',
                },
              ]}>
              {sp.result != null ? sp.result.priority : ''}
            </Text>
            <Text style={styles.cell}>
              {sp.result != null
                ? sp.result.keyBusinessRisks
                    .map(v => `${v}\n`)
                    .toLocaleString()
                    .replaceAll(',', '')
                : ''}
            </Text>
            <Text style={styles.bigCell}>{sp.result != null ? sp.result.recommendation : ''}</Text>
            <Text style={styles.bigCell}>{sp.actionPlan != null ? sp.actionPlan.description : ''}</Text>
            <Text style={styles.extraSmallCell}>
              {sp.actionPlan != null
                ? sp.actionPlan.status === ACTION_PLAN_STATUS.DONE
                  ? 'Done'
                  : sp.actionPlan.status === ACTION_PLAN_STATUS.NOT_PROGRAMMABLE
                  ? 'N/A'
                  : sp.actionPlan.dueDate
                  ? sp.actionPlan.dueDate
                  : ''
                : ''}
            </Text>
            <Text style={styles.cell}>{sp.actionPlan != null ? sp.actionPlan.supervisor : ''}</Text>
          </View>
        ))}
    </Page>

    {showTopics(assessmentDetail) && (
      <Page size='A4' orientation='landscape' style={styles.page} wrap>
        <View style={styles.rowHeader} fixed>
          <Text style={[styles.cell, { fontSize: 5 }]}>Topic for discussion</Text>
          <Text style={[styles.bigCell, { fontSize: 5 }]}>Comments</Text>
          <Text style={[styles.smallCell, { fontSize: 5 }]}>Owner</Text>
          <Text style={[styles.extraSmallCell, { fontSize: 5 }]}>Timestamp</Text>
        </View>
        {assessmentDetail.audit.topicsForDiscussion.flatMap(topic =>
          topic.comments.map((comment, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <View style={styles.row} wrap={false}>
              <Text style={index === topic.comments.length - 1 ? styles.cell : styles.topicTitleCell}>
                {index === 0 ? topic.title : ''}
              </Text>
              <Text style={styles.bigCell}>{comment.message} </Text>
              <Text style={styles.smallCell}>{comment.author.name}</Text>
              <Text style={styles.extraSmallCell}>{new Date(comment.created).toLocaleString()}</Text>
            </View>
          ))
        )}
      </Page>
    )}
  </Document>
);
