import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Brand } from '../../../shared/model/brand.model';
import { AuditCampaign, AuditCampaignRequest } from '../model/auditCampaign';
import { Region } from '../../../shared/model/region.model';
import { addCampaign, addProposal, selectVisibleCampaignProposals } from './auditCampaignsSlice';
import { checkValueInclude } from '../../../shared/utils';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import { AuditProposal, AuditProposalRequest } from '../model/auditProposal';
import { toastService } from '../../../core/services/toastService';
import { selectPrincipal } from '../../auth/store/principalSlice';

export enum WIZARD_STEP {
  SELECT_CAMPAIGN = 'SELECT_CAMPAIGN',
  CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
  CREATE_PROPOSAL = 'CREATE_PROPOSAL',
  LOADING = 'LOADING',
}

interface AuditProposalWizardSliceState {
  campaign: AuditCampaign | null;
  step: WIZARD_STEP;
  isFetching: boolean;
  error: string;
  brands: Brand[];
  regions: Region[];
  filter: string;
}

const initialState: AuditProposalWizardSliceState = {
  campaign: null,
  step: WIZARD_STEP.SELECT_CAMPAIGN,
  isFetching: false,
  error: '',
  brands: [],
  regions: [],
  filter: '',
};
export const auditProposalWizardSlice = createSlice({
  name: 'auditProposalWizard',
  initialState,
  reducers: {
    startFetch: (state: Draft<AuditProposalWizardSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishBrandFetch: (state: Draft<AuditProposalWizardSliceState>, action: PayloadAction<Brand[]>) => ({
      ...state,
      isFetching: false,
      brands: [...action.payload],
      error: '',
    }),
    finishRegionFetch: (state: Draft<AuditProposalWizardSliceState>, action: PayloadAction<Region[]>) => ({
      ...state,
      isFetching: false,
      regions: [...action.payload],
      error: '',
    }),
    httpError: (state: Draft<AuditProposalWizardSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    setCampaignFilter: (state: Draft<AuditProposalWizardSliceState>, action: PayloadAction<string>) => ({
      ...state,
      filter: action.payload,
    }),
    setCampaign: (state: Draft<AuditProposalWizardSliceState>, action: PayloadAction<AuditCampaign>) => ({
      ...state,
      campaign: action.payload,
    }),
    setStep: (state: Draft<AuditProposalWizardSliceState>, action: PayloadAction<WIZARD_STEP>) => ({
      ...state,
      step: action.payload,
    }),
    reset: (state: Draft<AuditProposalWizardSliceState>) => initialState,
    resetRegions: (state: Draft<AuditProposalWizardSliceState>) => ({
      ...state,
      regions: [],
    }),
  },
});
export const {
  startFetch,
  finishBrandFetch,
  httpError,
  setCampaignFilter,
  setCampaign,
  setStep,
  reset,
  finishRegionFetch,
  resetRegions,
} = auditProposalWizardSlice.actions;

export const fetchBrands = (): AppThunk => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    const brands: Brand[] = (await api.getBrands()).sort((b1, b2) => b1.description.localeCompare(b2.description));
    dispatch(finishBrandFetch(brands));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const fetchRegionsByBrands =
  (brand: Brand): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      dispatch(resetRegions());
      const regions: Region[] = (await api.getBrandRegions(brand.code)).sort((b1, b2) =>
        b1.description.localeCompare(b2.description)
      );
      dispatch(finishRegionFetch(regions));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export default auditProposalWizardSlice.reducer;

export const onFilterChange =
  (filter: string): AppThunk =>
  (dispatch: any) => {
    dispatch(setCampaignFilter(filter));
  };

export const onCampaignCreation =
  (newCampaign: AuditCampaign): AppThunk =>
  (dispatch: any) => {
    if (newCampaign != null) {
      dispatch(setCampaign(newCampaign));
    }
  };

export const onCampaignSelection =
  (id: string): AppThunk =>
  (dispatch: any, state: () => RootState) => {
    const { campaigns } = state().auditCampaigns;
    const campaign = campaigns.find(c => c.id === id);
    if (campaign != null) {
      dispatch(setCampaign(campaign));
    }
  };

export const onStepChange =
  (step: WIZARD_STEP): AppThunk =>
  (dispatch: any) => {
    dispatch(setStep(step));
  };

export const resetToInitialState = (): AppThunk => (dispatch: any) => dispatch(reset());
export const onResetRegions = (): AppThunk => (dispatch: any) => dispatch(resetRegions());

export const createProposal =
  (campaignId: string, request: AuditProposalRequest): AppThunk<Promise<AuditProposal | null>> =>
  async (dispatch: any): Promise<AuditProposal | null> => {
    try {
      const proposal = await api.createAuditProposal(campaignId, request);
      await dispatch(addProposal({ campaignId, proposal }));
      dispatch(reset());
      toastService.success();
      return proposal;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const createCampaign =
  (request: AuditCampaignRequest): AppThunk<Promise<AuditCampaign | null>> =>
  async (dispatch: any): Promise<AuditCampaign | null> => {
    try {
      const newCampaign = await api.createAuditCampaign(request);
      dispatch(addCampaign(newCampaign));
      dispatch(reset());
      toastService.success();
      return newCampaign;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const selectCampaignFilter = (state: RootState) => {
  return state.auditProposalWizard.filter;
};

export const selectFilteredCampaigns = createSelector(
  selectVisibleCampaignProposals,
  selectCampaignFilter,
  (visibleCampaigns, filter) => {
    return visibleCampaigns.filter(campaign => checkValueInclude(campaign.name, filter));
  }
);

export const selectFilteredAndOwnedCampaigns = createSelector(
  selectFilteredCampaigns,
  selectPrincipal,
  (filteredCampaigns, principal) => {
    return filteredCampaigns.filter(campaign => campaign.owner.email === principal.email);
  }
);

export const selectStep = (state: RootState) => state.auditProposalWizard.step;
export const selectBrands = (state: RootState) => state.auditProposalWizard.brands;
export const selectRegions = (state: RootState) => state.auditProposalWizard.regions;

export const selectCampaign = (state: RootState) => state.auditProposalWizard.campaign;
export const selectIsFetching = (state: RootState) => state.auditProposalWizard.isFetching;
