import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../shared/global.style';
import fieldStyle from './field.style';
import { ResultStatus } from '../model/assessmentDetail.model';

const container = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
`;

const content = css`
  flex-grow: 1;
  color: var(--saa-primary-color);
  padding: 0 0 10px;
  overflow: auto;
`;

const footer = css`
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 5px solid var(--saa-white-color);
`;

const infoButton = css`
  &&& {
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin-right: auto;
  }
`;

const infoIcon = css`
  && {
    color: var(--saa-blue-90);
    font-size: 2.143rem;
  }
`;

const infoModal = css`
  && {
    max-height: 100%;
    max-width: 400px;
    min-height: 300px;
  }
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${fieldStyle.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const infoModalContent = css`
  && {
  }
`;

const saveButton = css`
  ${globalStyle.primaryButton};
`;

const submitAndSaveContainer = css`
  && {
    display: flex;
  }
`;
const submitAndSave = css`
  ${globalStyle.primaryButton};
`;

const checkBoxContainer = css`
  ${fieldStyle.checkBoxContainer};
`;

const opacity = css`
  opacity: 0.5;
`;

const freeTextTitle = css`
  ${globalStyle.subtitleNormal}
  padding: 0 10px;
  background-color: var(--saa-blue-20);
`;

const freeTextContainerFailed = (status: ResultStatus): SerializedStyles => css`
  padding-bottom: 10px;
  > div {
    > div {
      > div,
      > i,
      > span {
        display: ${status !== ResultStatus.FAILED ? 'none' : ''};
      }
    }
  }
`;

const freeTextContainerPassed = (status: ResultStatus): SerializedStyles => css`
  padding-bottom: 10px;
  > div {
    > div {
      > div,
      > i,
      > span {
        display: ${status !== ResultStatus.PASSED ? 'none' : ''};
      }
    }
  }
`;

const observationContainer = css`
  padding-bottom: 10px;
`;

const auditNoteContainer = (noPaddingBefore: boolean) => css`
  ${noPaddingBefore ? '' : '  padding-top: 10px;'};
  padding-bottom: 10px;
`;

const modalConfirm = css`
  ${globalStyle.modalConfirm}
`;

export default {
  container,
  content,
  footer,
  infoButton,
  infoIcon,
  infoModal,
  infoModalContent,
  saveButton,
  submitAndSave,
  checkBoxContainer,
  freeTextContainerFailed,
  freeTextContainerPassed,
  freeTextTitle,
  opacity,
  auditNoteContainer,
  observationContainer,
  submitAndSaveContainer,
  modalConfirm,
  actions,
  button,
};
