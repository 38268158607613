/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Store } from '../../../shared/model/store.model';
import api from '../utils/api';
import { DEFAULT_CACHE_TTL } from '../../../constants';

interface StoresSliceState {
  data: Store[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
  filter: string;
  brandCode: string;
  region: string;
}

const initialState: StoresSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
  filter: '',
  brandCode: '',
  region: '',
};

const availableStoresSlice = createSlice({
  name: 'availableStores',
  initialState,
  reducers: {
    startFetch: (state: Draft<StoresSliceState>) => {
      state.isFetching = true;
    },
    finishFetch: (state: Draft<StoresSliceState>, action: PayloadAction<Store[]>) => {
      state.data = action.payload;
      state.isFetching = false;
      state.error = '';
      state.isValidCache = true;
    },
    errorFetch: (state: Draft<StoresSliceState>, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isFetching = false;
      state.isValidCache = false;
    },
    invalidateCache: (state: Draft<StoresSliceState>) => ({ ...state, isValidCache: false }),
    filterChange: (state: Draft<StoresSliceState>, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setBrand: (state: Draft<StoresSliceState>, action: PayloadAction<string>) => {
      state.brandCode = action.payload;
    },
    setRegion: (state: Draft<StoresSliceState>, action: PayloadAction<string>) => {
      state.region = action.payload;
    },
    reset: () => initialState,
  },
});

// actions generated from the slice
export const { startFetch, finishFetch, errorFetch, invalidateCache, filterChange, setBrand, setRegion, reset } =
  availableStoresSlice.actions;

// A selector
export const selectAvailableStores = (state: any) => {
  return state.availableStores.data as Store[];
};

export const selectAvailableStoresFilter = (state: any): string => state.availableStores.filter;

export const isFetchingAvailableStores = (state: any) => {
  return state.availableStores.isFetching as boolean;
};

export const fetchAvailableStores = () => async (dispatch: any, state: any) => {
  dispatch(startFetch());
  try {
    const { filter, brandCode, region } = state().availableStores;
    const availableStores = filter !== '' ? await api.getAvailableStores(filter, brandCode, region) : [];
    dispatch(finishFetch(availableStores));
  } catch (error) {
    dispatch(errorFetch(JSON.stringify(error)));
  }
  setTimeout(() => dispatch(invalidateCache()), DEFAULT_CACHE_TTL);
};

// The reducer
export default availableStoresSlice.reducer;
