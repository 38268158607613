import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { Country } from '../../../shared/model/country.model';

interface KeringCountriesSliceState {
  data: Country[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
}

export const initialState: KeringCountriesSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
};

const keringCountriesSlice = createSlice({
  name: 'keringCountries',
  initialState,
  reducers: {
    startFetch: (state: Draft<KeringCountriesSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<KeringCountriesSliceState>, { payload }: PayloadAction<Country[]>) => {
      return {
        isFetching: false,
        data: [...payload],
        error: '',
        isValidCache: true,
      };
    },
    errorFetch: (state: Draft<KeringCountriesSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    invalidateCache: (state: Draft<KeringCountriesSliceState>) => ({ ...state, isValidCache: false }),
  },
});
export const { startFetch, finishFetch, errorFetch, invalidateCache } = keringCountriesSlice.actions;

export const fetchKeringCountries = (regions: string[]) => async (dispatch: any, state: any) => {
  if (!state().keringCountries.isFetching) {
    dispatch(startFetch());
    try {
      const keringCountries = (await api.getKeringCountries({ regions })).sort((r1, r2) =>
        r1.description.localeCompare(r2.description)
      );
      dispatch(finishFetch(keringCountries));
    } catch (error) {
      dispatch(errorFetch(JSON.stringify(error)));
    }
  }
};

export const clearKeringCountries = () => async (dispatch: any, state: any) => {
  if (!state().keringCountries.isFetching) {
    dispatch(startFetch());
    try {
      dispatch(finishFetch([]));
    } catch (error) {
      dispatch(errorFetch(JSON.stringify(error)));
    }
  }
};

export const selectKeringCountries = (state: any) => state.keringCountries.data as Country[];

export const selectIsFetchingKeringCountries = (state: any) => state.keringCountries.isFetching;

export default keringCountriesSlice.reducer;
