/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Icon } from 'semantic-ui-react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import {
  AttachmentRef,
  AuditingResult,
  PictureRef,
  Priority,
  ResultStatus,
  SubProcess,
  SubProcessResult,
} from '../model/assessmentDetail.model';
import style from './auditFix.style';
import { FreeTextField } from './FreeTextField';
import { PriorityField } from './PriorityField';
import { SubProcessResultBox } from './SubProcessResultBox';
import { AttachmentsListView } from './AttachmentsListView';
import { MultiSelectField } from './MultiSelectField';

interface AuditFixProps {
  subProcess: SubProcess;
  onChange: (result: SubProcessResult) => void;
}
export const AuditFix = ({ subProcess, onChange }: AuditFixProps): JSX.Element => {
  const { t } = useTranslation();
  const [result, setResult] = useState(subProcess.result);
  const [accordionsStatuses, setAccordionsStatuses] = useState(
    subProcess.auditingResults.map(ar => ({ id: ar.id, active: false }))
  );
  const [resultAccordionStatus, setResultAccordionStatus] = useState(true);

  useEffect(() => setResult(subProcess.result), [subProcess]);
  useEffect(
    () => setAccordionsStatuses(subProcess.auditingResults.map(ar => ({ id: ar.id, active: false }))),
    [subProcess.auditingResults]
  );
  useEffect(() => setResultAccordionStatus(true), [subProcess.result]);

  const onChangeResult = useCallback(
    (newResult: SubProcessResult) => {
      setResult(newResult);
      onChange(newResult);
    },
    [onChange]
  );

  const isActive = useCallback(
    (ar: AuditingResult) => {
      const acStatus = accordionsStatuses.find(as => as.id === ar.id);
      return acStatus && acStatus.active;
    },
    [accordionsStatuses]
  );

  const toggleAccordion = (id: string) => {
    setAccordionsStatuses(prevState => {
      const status = prevState.filter(v => v.id === id)[0].active;
      return [...prevState.filter(as => as.id !== id), { id, active: !status }];
    });
  };

  const closeAllAuditingResultAccordions = () => {
    setAccordionsStatuses(prevState => {
      return prevState.map(v => ({ id: v.id, active: false }));
    });
  };

  return (
    <div>
      <div css={style.title} title={`${subProcess.code} ${subProcess.description}`}>
        {`${subProcess.code} ${subProcess.description}`}
      </div>
      {subProcess.auditingResults.map(ar => (
        <ResultItem
          key={ar.id}
          title={ar.auditor.name}
          active={isActive(ar)}
          toggleAccordion={() => toggleAccordion(ar.id)}>
          <div css={style.resultBoxContainer}>
            <SubProcessResultBox name={ar.id} status={ar.status} />
          </div>
          <Fields result={ar} />
          {ar.status !== ResultStatus.TO_DO ? (
            <div css={style.copyButtonContainer}>
              <Button
                css={style.copyButton}
                onClick={() => {
                  closeAllAuditingResultAccordions();
                  setResultAccordionStatus(true);
                  const { id, auditor, ...resultCopy } = ar;
                  onChangeResult({ ...resultCopy, pictures: result.pictures });
                }}>
                <Icon name='check circle' />
                {t('assessment.audit.copy')}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </ResultItem>
      ))}
      <ResultItem
        title='Result'
        active={resultAccordionStatus}
        toggleAccordion={() => {
          setResultAccordionStatus(prevState => !prevState);
        }}>
        <div css={style.resultBoxContainerWithValidation(result.status)}>
          <SubProcessResultBox
            readonly={false}
            showTitle={false}
            mutable
            status={result.status}
            onChange={newStatus => onChangeResult({ ...result, status: newStatus })}
          />
        </div>
        <Fields
          result={result}
          readonly={false}
          onChange={value => onChangeResult(value)}
          subProcessAvailableKeyBusinessRisks={subProcess.availableKeyBusinessRisks}
        />
        <div css={style.picturesListContainer}>
          <AttachmentsListView
            pictures={result.pictures}
            attachments={result.attachments}
            onDeleteAttachment={(attch: AttachmentRef) =>
              onChange({ ...result, attachments: result.attachments.filter(p => p.id !== attch.id) })
            }
            onAddAttachments={attchs => onChange({ ...result, attachments: result.attachments.concat(attchs) })}
            onDeletePicture={(pic: PictureRef) =>
              onChange({ ...result, pictures: result.pictures.filter(p => p.id !== pic.id) })
            }
            onAddPictures={pics => onChange({ ...result, pictures: result.pictures.concat(pics) })}
            editable={result.status === ResultStatus.FAILED || result.status === ResultStatus.PASSED}
          />
        </div>
      </ResultItem>
    </div>
  );
};

interface ResultItemProps {
  title: string;
  active?: boolean;
  children?: JSX.Element[] | JSX.Element;
  toggleAccordion: () => void;
}

const ResultItem: React.FunctionComponent<ResultItemProps> = ({
  title,
  active = false,
  children,
  toggleAccordion,
}): JSX.Element => {
  return (
    <Accordion css={style.result}>
      <Accordion.Title active={active} css={style.resultTitle} onClick={() => toggleAccordion()}>
        <Icon name='dropdown' />
        <div>{title}</div>
      </Accordion.Title>
      <Accordion.Content active={active} css={style.resultContent}>
        <Accordion.Content active={active}>{children}</Accordion.Content>
      </Accordion.Content>
    </Accordion>
  );
};

interface FieldsProps {
  result: SubProcessResult;
  readonly?: boolean;
  onChange?: (result: SubProcessResult) => void;
  subProcessAvailableKeyBusinessRisks?: string[];
}

const Fields = ({
  result,
  readonly = true,
  onChange = () => null,
  subProcessAvailableKeyBusinessRisks = [],
}: FieldsProps): JSX.Element => {
  return (
    <div css={content}>
      <div css={freeTextContainer}>
        <FreeTextField
          title='Observation'
          mutable
          defaultValue={
            result.status === ResultStatus.FAILED || result.status === ResultStatus.PASSED ? result.observation : ''
          }
          editable={(result.status === ResultStatus.FAILED || result.status === ResultStatus.PASSED) && !readonly}
          onChange={value => onChange({ ...result, observation: value })}
        />
      </div>
      <div css={freeTextContainer}>
        <FreeTextField
          title='Finding'
          mutable
          defaultValue={result.status === ResultStatus.FAILED ? result.finding : ''}
          editable={result.status === ResultStatus.FAILED && !readonly}
          onChange={value => onChange({ ...result, finding: value })}
          isValid={
            result.status === ResultStatus.TO_DO ||
            readonly ||
            result.status === ResultStatus.PASSED ||
            (result.finding != null && result.finding !== '')
          }
        />
      </div>
      <div css={freeTextContainer}>
        <FreeTextField
          title='Recommendation'
          mutable
          defaultValue={result.status === ResultStatus.FAILED ? result.recommendation : ''}
          editable={result.status === ResultStatus.FAILED && !readonly}
          onChange={value => onChange({ ...result, recommendation: value })}
          isValid={
            result.status === ResultStatus.TO_DO ||
            readonly ||
            result.status === ResultStatus.PASSED ||
            (result.recommendation != null && result.recommendation !== '')
          }
        />
      </div>
      <div css={freeTextContainer}>
        <MultiSelectField
          title='Key business risks'
          defaultValue={
            result.status === ResultStatus.FAILED
              ? subProcessAvailableKeyBusinessRisks.length === 1
                ? [...subProcessAvailableKeyBusinessRisks]
                : result.keyBusinessRisks
              : []
          }
          editable={
            result.status === ResultStatus.FAILED && !readonly && subProcessAvailableKeyBusinessRisks.length > 1
          }
          onChange={value => onChange({ ...result, keyBusinessRisks: value })}
          options={subProcessAvailableKeyBusinessRisks}
          isValid={
            result.status === ResultStatus.TO_DO ||
            readonly ||
            result.status === ResultStatus.PASSED ||
            (result.keyBusinessRisks != null && result.keyBusinessRisks.length > 0) ||
            subProcessAvailableKeyBusinessRisks.length === 1
          }
          mutable
        />
      </div>
      <div>
        <PriorityField
          defaultValue={
            result.status === ResultStatus.FAILED
              ? { priorityRationale: result.priorityRationale, priority: result.priority }
              : { priorityRationale: '', priority: Priority.PASS }
          }
          editable={result.status === ResultStatus.FAILED && !readonly}
          mutable
          onChange={value =>
            onChange({ ...result, priority: value.priority, priorityRationale: value.priorityRationale })
          }
          isValid={
            result.status === ResultStatus.TO_DO ||
            readonly ||
            result.status === ResultStatus.PASSED ||
            (result.priorityRationale != null &&
              result.priorityRationale !== '' &&
              result.priority != null &&
              result.priority !== Priority.PASS)
          }
        />
      </div>
    </div>
  );
};

const content = css`
  flex-grow: 1;
  color: var(--saa-primary-color);
  padding: 10px;
`;

const freeTextContainer = css`
  padding-bottom: 10px;
`;
