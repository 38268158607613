/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  fetchAvailableStores,
  filterChange,
  isFetchingAvailableStores,
  selectAvailableStores,
  selectAvailableStoresFilter,
  setBrand,
  setRegion,
} from '../store/availableStoresSlice';
import { SearchBox } from '../../../shared/SearchBox';
import style from './selectAvailableStores.style';
import { BrandImage } from '../../../shared/BrandImage';
import { Store } from '../../../shared/model/store.model';
import { useAppDispatch } from '../../../core/store';

interface SelectAvailableStoresProps {
  onStoreSelect: (store: Store) => void;
  brandCode?: string;
  region?: string;
}

export const SelectAvailableStores = ({
  onStoreSelect,
  brandCode = '',
  region = '',
}: SelectAvailableStoresProps): JSX.Element => {
  const { t } = useTranslation();
  const availableStores = useSelector(selectAvailableStores);
  const storesFilter = useSelector(selectAvailableStoresFilter);
  const isFetchingStores = useSelector(isFetchingAvailableStores);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBrand(brandCode));
    dispatch(setRegion(region));
    dispatch(fetchAvailableStores());
  }, [dispatch, storesFilter, brandCode, region]);

  const showSelectionContainer = useMemo(
    () => availableStores.length > 0 || isFetchingStores,
    [availableStores.length, isFetchingStores]
  );

  return (
    <div css={style.container}>
      <div css={!showSelectionContainer && style.searchBoxContainer}>
        {!showSelectionContainer && <p css={style.emptyMessage}>{t('assessment.selectStore')}</p>}
        <SearchAvailableStoreBox />
      </div>
      {showSelectionContainer && (
        <div css={style.selectionContainer}>
          {isFetchingStores ? (
            <Dimmer active inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
          ) : (
            availableStores.map(value => <StoreSelection key={value.jdaCode} store={value} onSelect={onStoreSelect} />)
          )}
        </div>
      )}
    </div>
  );
};

interface StoreSelectionProps {
  store: Store;
  onSelect: (store: Store) => void;
}

const StoreSelection = ({ store, onSelect }: StoreSelectionProps): JSX.Element => {
  return (
    <div css={style.store} onClick={() => onSelect(store)}>
      <BrandImage src={store.brand.logo} />
      <div css={style.titleContainer}>
        <span css={style.title}>{store.name}</span>
        <span css={style.subTitle}>{store.jdaCode}</span>
      </div>
    </div>
  );
};

const SearchAvailableStoreBox = (): JSX.Element => {
  const availableStoresFilter = useSelector(selectAvailableStoresFilter);
  const dispatch = useAppDispatch();
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(filterChange(value));
  }, 500);
  return (
    <div css={style.search}>
      <SearchBox defaultValue={availableStoresFilter} onChange={debounced} autoFocus />
    </div>
  );
};
