/** @jsxImportSource @emotion/react */
import * as React from 'react';
import qs from 'query-string';
import { Button, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import keringLogo from '../../../assets/buttonKeringLogo.png';
import style from './oauthSenderButton.style';

type OauthSenderPros = {
  authorizeUrl: string;
  clientId: string;
  redirectUri: string;
};

export const OauthSenderButton = ({ authorizeUrl, clientId, redirectUri }: OauthSenderPros): JSX.Element => {
  const { t } = useTranslation();
  const params = qs.stringify({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: 'Email Profile Openid',
  });

  return (
    <Button css={style.button} onClick={() => window.location.assign(`${authorizeUrl}?${params}`)}>
      <div css={style.buttonContentContainer}>
        <div css={style.imageContainer}>
          <Image src={keringLogo} />
        </div>
        <span>{t('keringAuth')}</span>
      </div>
    </Button>
  );
};
