import axios from 'axios';
import qs from 'query-string';
import paths from './paths';
import {
  AuditProposal,
  AuditProposalRequest,
  AuditProposalWithCampaignInfo,
  ChangeStatusRequest,
  toAuditProposal,
  toAuditProposalWithCampaignInfo,
  UpdateProposalAuditorsRequest,
  UpdateProposalDateRequest,
} from '../model/auditProposal';
import {
  AuditCampaign,
  AuditCampaignRequest,
  toAuditCampaign,
  UpdateCampaignBrandSchedulersRequest,
  UpdateCampaignDistributionListRequest,
  UpdateCampaignNameRequest,
} from '../model/auditCampaign';
import { addLogoToBrand, Brand, toBrand } from '../../../shared/model/brand.model';
import { Region, toRegion } from '../../../shared/model/region.model';
import { Store, toStore } from '../../../shared/model/store.model';
import {
  BrandAuditProposal,
  BrandAuditProposalRequest,
  BrandProposalChangeStatusRequest,
  toBrandAuditProposal,
  UpdateBrandProposalAuditorsRequest,
  UpdateBrandProposalBrandSchedulersRequest,
  UpdateBrandProposalDateRequest,
  UpdateBrandProposalDistributionListRequest,
  UpdateBrandProposalProposedByBrandDateRequest,
} from '../model/brandAuditProposal';
import { BrandScheduler, BrandSchedulerQueryParams, toBrandScheduler } from '../model/brandScheduler';
import { ProposalQueryParams } from '../model/proposalsQueryParams';

const api = {
  getAuditCampaigns: async (): Promise<AuditCampaign[]> => {
    const response = await axios.get(paths.getAuditCampaigns());
    return response.data.map(toAuditCampaign);
  },
  createAuditCampaign: async (request: AuditCampaignRequest): Promise<AuditCampaign> => {
    const response = await axios.post(paths.createAuditCampaign(), request);
    return toAuditCampaign(response.data);
  },
  getAuditCampaign: async (campaignId: string): Promise<AuditCampaign> => {
    const response = await axios.get(paths.getAuditCampaign(campaignId));
    return toAuditCampaign(response.data);
  },
  deleteAuditCampaign: async (campaignId: string): Promise<AuditCampaign> => {
    const response = await axios.delete(paths.deleteAuditCampaign(campaignId));
    return toAuditCampaign(response.data);
  },
  updateAuditCampaignName: async (campaignId: string, request: UpdateCampaignNameRequest): Promise<AuditCampaign> => {
    const response = await axios.put(paths.updateAuditCampaignName(campaignId), request);
    return toAuditCampaign(response.data);
  },
  updateAuditCampaignDistributionList: async (
    campaignId: string,
    request: UpdateCampaignDistributionListRequest
  ): Promise<AuditCampaign> => {
    const response = await axios.put(paths.updateAuditCampaignDistributionList(campaignId), request);
    return toAuditCampaign(response.data);
  },
  updateAuditCampaignBrandSchedulers: async (
    campaignId: string,
    request: UpdateCampaignBrandSchedulersRequest
  ): Promise<AuditCampaign> => {
    const response = await axios.put(paths.updateAuditCampaignBrandSchedulers(campaignId), request);
    return toAuditCampaign(response.data);
  },
  approveCampaignProposals: async (campaignId: string, request: ChangeStatusRequest): Promise<AuditCampaign> => {
    const response = await axios.post(paths.approveCampaignProposals(campaignId), request);
    return toAuditCampaign(response.data);
  },
  getAuditProposal: async (campaignId: string, proposalId: string): Promise<AuditProposal> => {
    const response = await axios.get(paths.getAuditProposal(campaignId, proposalId));
    return toAuditProposal(response.data);
  },
  deleteAuditProposal: async (campaignId: string, proposalId: string): Promise<AuditProposal> => {
    const response = await axios.delete(paths.deleteAuditProposal(campaignId, proposalId));
    return toAuditProposal(response.data);
  },
  getBrandSchedulers: async (params: BrandSchedulerQueryParams): Promise<BrandScheduler[]> => {
    const response = await axios.get(paths.getBrandSchedulers(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });

    return response.data.map(toBrandScheduler);
  },
  updateProposedDate: async (
    campaignId: string,
    proposalId: string,
    request: UpdateProposalDateRequest
  ): Promise<AuditProposal> => {
    const response = await axios.put(paths.updateProposedDate(campaignId, proposalId), request);
    return toAuditProposal(response.data);
  },
  updateProposalAuditors: async (
    campaignId: string,
    proposalId: string,
    request: UpdateProposalAuditorsRequest
  ): Promise<AuditProposal> => {
    const response = await axios.put(paths.updateProposalAuditors(campaignId, proposalId), request);
    return toAuditProposal(response.data);
  },
  createAuditProposal: async (campaignId: string, request: AuditProposalRequest): Promise<AuditProposal> => {
    const response = await axios.post(paths.createAuditProposal(campaignId), request);
    return toAuditProposal(response.data);
  },
  getBrands: async (): Promise<Brand[]> => {
    const response = await axios.get(paths.getBrands());
    return response.data.map(addLogoToBrand);
  },
  getBrandRegions: async (brandCode: string): Promise<Region[]> => {
    const response = await axios.get(paths.getBrandRegions(brandCode));
    return response.data.map(toRegion);
  },
  changeStatus: async (
    campaignId: string,
    proposalId: string,
    request: ChangeStatusRequest
  ): Promise<AuditProposal> => {
    const response = await axios.post(paths.changeStatus(campaignId, proposalId), request);
    return toAuditProposal(response.data);
  },
  getBrandAuditProposals: async (): Promise<BrandAuditProposal[]> => {
    const response = await axios.get(paths.getBrandAuditProposals());
    return response.data.map(toBrandAuditProposal);
  },
  createBrandAuditProposals: async (request: BrandAuditProposalRequest): Promise<BrandAuditProposal> => {
    console.log(request);
    
    const response = await axios.post(paths.createBrandAuditProposals(), request);
    return toBrandAuditProposal(response.data);
  },
  getAvailableStores: async (text: string): Promise<Store[]> => {
    const params = { text };
    const response = await axios.get(paths.getAvailableStores(), { params });
    return response.data.map(toStore);
  },
  getAuditProposals: async (from: string, to: string): Promise<AuditProposalWithCampaignInfo[]> => {
    const response = await axios.get(paths.getAuditProposals(), { params: { from, to } });
    return response.data.map(toAuditProposalWithCampaignInfo);
  },
  brandProposalChangeStatus: async (
    proposalId: string,
    request: BrandProposalChangeStatusRequest
  ): Promise<BrandAuditProposal> => {
    const response = await axios.post(paths.brandProposalChangeStatus(proposalId), request);
    return toBrandAuditProposal(response.data);
  },
  getBrandAuditProposal: async (proposalId: string): Promise<BrandAuditProposal> => {
    const response = await axios.get(paths.getBrandAuditProposal(proposalId));
    return toBrandAuditProposal(response.data);
  },
  updateBrandProposalDate: async (
    proposalId: string,
    request: UpdateBrandProposalDateRequest
  ): Promise<BrandAuditProposal> => {
    const response = await axios.put(paths.updateBrandProposalDate(proposalId), request);
    return toBrandAuditProposal(response.data);
  },
  updateBrandProposalProposedByBrandDate: async (
    proposalId: string,
    request: UpdateBrandProposalProposedByBrandDateRequest
  ): Promise<BrandAuditProposal> => {
    const response = await axios.put(paths.updateBrandProposalProposedByBrandDate(proposalId), request);
    return toBrandAuditProposal(response.data);
  },
  deleteBrandAuditProposal: async (proposalId: string): Promise<BrandAuditProposal> => {
    const response = await axios.delete(paths.deleteBrandAuditProposal(proposalId));
    return toBrandAuditProposal(response.data);
  },
  updateBrandProposalBrandScheduler: async (
    proposalId: string,
    request: UpdateBrandProposalBrandSchedulersRequest
  ): Promise<BrandAuditProposal> => {
    const response = await axios.put(paths.updateBrandProposalBrandScheduler(proposalId), request);
    return toBrandAuditProposal(response.data);
  },
  updateBrandProposalDistributionList: async (
    proposalId: string,
    request: UpdateBrandProposalDistributionListRequest
  ): Promise<BrandAuditProposal> => {
    const response = await axios.put(paths.updateBrandProposalDistributionList(proposalId), request);
    return toBrandAuditProposal(response.data);
  },
  updateBrandProposalAuditors: async (
    proposalId: string,
    request: UpdateBrandProposalAuditorsRequest
  ): Promise<BrandAuditProposal> => {
    const response = await axios.put(paths.updateBrandProposalAuditors(proposalId), request);
    return toBrandAuditProposal(response.data);
  },
  getProposalsBrands: async (params: ProposalQueryParams): Promise<Brand[]> => {
    const response = await axios.get(paths.getProposalsBrands(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toBrand);
  },
  getProposalsRegions: async (params: ProposalQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getProposalsRegions(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getProposalsCountries: async (params: ProposalQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getProposalsCountries(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getProposalsCities: async (params: ProposalQueryParams): Promise<string[]> => {
    const response = await axios.get(paths.getProposalsCities(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data as string[];
  },
  getProposalsStores: async (params: ProposalQueryParams): Promise<Store[]> => {
    const response = await axios.get(paths.getProposalsStores(), {
      params,
      paramsSerializer: p => {
        return qs.stringify(p);
      },
    });
    return response.data.map(toStore);
  },
};

export default api;
