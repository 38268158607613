/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as emailValidator from 'email-validator';
import { selectUsers } from '../store/usersSlice';
import { AdUser, User } from '../model/user.model';
import { ROLES } from '../../auth/model/principal.model';
import { SimpleField } from '../../../shared/SimpleField';
import style from './userCard.style';
import { AdUserDropdownField } from './AdUserDropdownField';
import { useCtrlEnterToRunCallback, useEscToRunCallback } from '../../../core/utils';
import { BrandCode, toBrand } from '../../../shared/model/brand.model';
import { fetchKeringRegions, selectKeringRegions, selectIsFetching } from '../store/keringRegionsSlice';
import { useAppDispatch } from '../../../core/store';
import { Region } from '../../../shared/model/region.model';
import {
  clearKeringCountries,
  fetchKeringCountries,
  selectIsFetchingKeringCountries,
  selectKeringCountries,
} from '../store/keringCountriesSlice';
import { Country } from '../../../shared/model/country.model';

export interface UserFields {
  email: string;
  firstName: string;
  familyName: string;
  roles: ROLES[];
  brands: BrandCode[];
  approvalRequired: boolean;
  isRegionalManagerOf: string[];
  regions: string[];
  countries: string[];
}

interface UserCardWritableProps {
  user: User;
  onSave: (fields: UserFields) => void;
  onCancel: () => void;
}

export const UserCardWritable = ({ user, onCancel, onSave }: UserCardWritableProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const users = useSelector(selectUsers);
  const [roles, setRoles] = useState(user.roles);
  const [brands, setBrands] = useState(user.brands);
  const [firstName, setFirstName] = useState(user.firstName);
  const [familyName, setFamilyName] = useState(user.familyName);
  const [email, setEmail] = useState(user.email);
  const [showErrors, setShowErrors] = useState(false);
  const [approvalRequired, setApprovalRequired] = useState(user.approvalRequired);
  const [selectedIsRegionalManagerOf, setSelectedIsRegionalManagerOf] = useState(user.isRegionalManagerOf);
  const [selectedRegions, setSelectedRegions] = useState(user.regions || []);
  const [selectedCountries, setSelectedCountries] = useState(user.countries || []);

  const keringRegions = useSelector(selectKeringRegions);
  const keringCountries = useSelector(selectKeringCountries);
  const isFetchingKeringCountries = useSelector(selectIsFetchingKeringCountries);
  const isFetchingKeringRegions = useSelector(selectIsFetching);

  useEffect(() => {
    dispatch(fetchKeringRegions());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRegions.length === 0) {
      dispatch(clearKeringCountries());
    } else {
      dispatch(fetchKeringCountries(selectedRegions));
    }
  }, [dispatch, selectedRegions]);

  const isEditMode = user.id !== '';

  const fullName = useMemo((): string => {
    if (firstName == null || familyName == null) {
      return '-';
    }
    return `${firstName} ${familyName}`;
  }, [firstName, familyName]);

  const isRoleAuditor = useMemo((): boolean => {
    return roles.includes(ROLES.AUDITOR);
  }, [roles]);

  const isRoleArchiveReaderOrBrandScheduler = useMemo((): boolean => {
    return roles.includes(ROLES.ARCHIVE_READER) || roles.includes(ROLES.BRAND_SCHEDULER);
  }, [roles]);

  const isEmailValid = useMemo(() => email.trim() !== '' && emailValidator.validate(email), [email]);

  const isEmailDuplicated = useMemo(() => {
    return users.some(u => u.id !== user.id && u.email === email);
  }, [email, users, user]);

  const isRolesValid = useMemo(() => roles.length > 0, [roles.length]);

  const isBrandsValid = useMemo(() => brands.length > 0, [brands.length]);

  const validateAndSave = useCallback(() => {
    setShowErrors(true);
    if (isRolesValid && isEmailValid && !isEmailDuplicated && isBrandsValid) {
      onSave({
        email,
        firstName,
        familyName,
        roles,
        brands,
        approvalRequired,
        isRegionalManagerOf: isRoleAuditor ? selectedIsRegionalManagerOf : [],
        regions: isRoleArchiveReaderOrBrandScheduler ? selectedRegions : [],
        countries: isRoleArchiveReaderOrBrandScheduler ? selectedCountries.map(country => country.code) : [],
      });
    }
  }, [
    approvalRequired,
    selectedIsRegionalManagerOf,
    isRolesValid,
    isEmailValid,
    isEmailDuplicated,
    isBrandsValid,
    onSave,
    email,
    firstName,
    familyName,
    roles,
    brands,
    selectedRegions,
    selectedCountries,
    isRoleArchiveReaderOrBrandScheduler,
    isRoleAuditor,
  ]);

  const emailErrorMsg = useMemo((): string => {
    if (email.trim() === '') {
      return t('users.emailRequired');
    }
    if (!isEmailValid) {
      return t('users.emailNotValid');
    }
    if (isEmailDuplicated) {
      return t('users.emailIsDuplicated');
    }
    return '';
  }, [email, isEmailDuplicated, isEmailValid, t]);

  const rolesErrorMsg = useMemo((): string => {
    if (!isRolesValid) {
      return t('users.rolesRequired');
    }
    return '';
  }, [isRolesValid, t]);

  const brandsErrorMsg = useMemo((): string => {
    if (!isBrandsValid) {
      return t('users.brandsRequired');
    }
    return '';
  }, [isBrandsValid, t]);

  const onChangeRoles = (rolesToUpdate: ROLES[]) => {
    if (!isRoleArchiveReaderOrBrandScheduler) {
      setSelectedRegions([]);
    }
    if (!isRoleAuditor) {
      setSelectedIsRegionalManagerOf([]);
    }
    setRoles(rolesToUpdate);
  };

  const onChangeIsRegionalManagerOf = (regionsToUpdate: string[]) => {
    setSelectedIsRegionalManagerOf(regionsToUpdate);
  };

  const onChangeRegions = (regionsToUpdate: string[]) => {
    setSelectedRegions(regionsToUpdate);
    setSelectedCountries([]);
  };

  const onChangeCountries = (countriesToUpdate: Country[]) => {
    setSelectedCountries(countriesToUpdate);
  };

  const onChangeBrands = (brandsToUpdate: BrandCode[]) => {
    setBrands(brandsToUpdate);
  };

  const onChangeEmail = (selectedUser?: AdUser) => {
    if (selectedUser) {
      setEmail(selectedUser.email);
      setFirstName(selectedUser.firstName);
      setFamilyName(selectedUser.familyName);
    } else {
      setEmail('');
      setFirstName('');
      setFamilyName('');
    }
  };

  useCtrlEnterToRunCallback(validateAndSave);
  useEscToRunCallback(onCancel);

  return (
    <div css={style.card}>
      <div css={style.info}>
        {isEditMode ? (
          <SimpleField value={email} title={t('users.email')} icon='mail' />
        ) : (
          <SimpleField
            errorMessage={emailErrorMsg}
            error={showErrors && (!isEmailValid || isEmailDuplicated)}
            title={t('users.email')}
            icon='mail'>
            <AdUserDropdownField
              error={showErrors && (!isEmailValid || isEmailDuplicated)}
              onChange={onChangeEmail}
              placeholder={t('users.search') || ''}
            />
          </SimpleField>
        )}
        <SimpleField value={fullName} title={t('users.name')} icon='user' css={isEditMode ? [] : [style.nameField]} />
      </div>
      <div css={style.roles}>
        <SimpleField
          css={[style.rolesField]}
          error={showErrors && !isRolesValid}
          errorMessage={rolesErrorMsg}
          title={t('users.roles')}
          icon='cogs'>
          <DropDownRoles error={showErrors && !isRolesValid} selectedRoles={roles} onChange={onChangeRoles} />
        </SimpleField>
      </div>
      {isRoleArchiveReaderOrBrandScheduler && (
        <div css={style.info}>
          <SimpleField title={t('users.regions')} icon='globe'>
            <DropDownRegions
              selectedRegions={selectedRegions}
              onChange={onChangeRegions}
              keringRegionList={keringRegions}
              loading={isFetchingKeringRegions}
            />
          </SimpleField>
          <SimpleField title={t('users.countries')} icon='globe'>
            <DropDownCountries
              selectedCountries={selectedCountries}
              onChange={onChangeCountries}
              keringCountryList={keringCountries}
              disabled={selectedRegions.length === 0}
              loading={isFetchingKeringCountries}
            />
          </SimpleField>
        </div>
      )}
      {isRoleAuditor && (
        <div css={style.info}>
          <SimpleField title={t('users.isRegionalManagerOf')} icon='globe'>
            <DropDownIsRegionalManagerOf
              selectedRegions={selectedIsRegionalManagerOf}
              onChange={onChangeIsRegionalManagerOf}
              keringRegionList={keringRegions}
              disabled={approvalRequired}
              loading={isFetchingKeringRegions}
            />
          </SimpleField>
          <SimpleField title='' icon='check'>
            <div css={style.checkBoxContainer}>
              <span css={style.checkBoxLabel}>{t('users.approvalRequired')}</span>
              <Checkbox
                css={style.checkBox}
                toggle
                checked={approvalRequired}
                disabled={selectedIsRegionalManagerOf.length !== 0}
                onChange={(e, data) => setApprovalRequired(data.checked as boolean)}
              />
            </div>
          </SimpleField>
        </div>
      )}

      <div css={style.brands}>
        <SimpleField
          css={[style.brandsField]}
          error={showErrors && !isBrandsValid}
          errorMessage={brandsErrorMsg}
          title={t('users.brands')}
          icon='copyright'>
          <DropDownBrands error={showErrors && !isBrandsValid} selectedBrands={brands} onChange={onChangeBrands} />
        </SimpleField>
      </div>
      <div css={style.buttonsContainer}>
        <Button content={t('users.cancel')} icon='times' onClick={onCancel} />
        <Button css={style.saveButton} content={t('users.save')} icon='save' onClick={() => validateAndSave()} />
      </div>
    </div>
  );
};

interface DropdownRolesProps {
  selectedRoles: ROLES[];
  onChange: (roles: ROLES[]) => void;
  error: boolean;
}

const DropDownRoles = ({ error, selectedRoles, onChange }: DropdownRolesProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dropdown
      compact
      css={style.dropDownRoles}
      placeholder={t('users.roles') || ''}
      value={selectedRoles}
      error={error}
      selection
      multiple
      fluid
      options={Object.values(ROLES).map(r => ({ key: r, text: r, value: r }))}
      onChange={(event, data: DropdownProps) => {
        const v = data.value as ROLES[];
        onChange(v);
      }}
    />
  );
};

interface DropdownBrandsProps {
  selectedBrands: BrandCode[];
  onChange: (brands: BrandCode[]) => void;
  error: boolean;
}

interface DropdownIsRegionalManagerOfProps {
  selectedRegions: string[];
  onChange: (regions: string[]) => void;
  keringRegionList: Region[];
  disabled?: boolean;
  loading: boolean;
}

interface DropdownRegionsProps {
  selectedRegions: string[];
  onChange: (regions: string[]) => void;
  keringRegionList: Region[];
  loading: boolean;
}

interface DropdownCountriesProps {
  selectedCountries: Country[];
  onChange: (countries: Country[]) => void;
  keringCountryList: Country[];
  disabled: boolean;
  loading: boolean;
}

const DropDownIsRegionalManagerOf = ({
  selectedRegions,
  onChange,
  keringRegionList,
  disabled = false,
  loading,
}: DropdownIsRegionalManagerOfProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dropdown
      compact
      loading={loading}
      css={style.dropDownIsRegionalManagerOf}
      placeholder={t('users.isRegionalManagerOf') || ''}
      value={selectedRegions}
      selection
      multiple
      fluid
      clearable
      disabled={loading || disabled}
      options={Object.values(keringRegionList).map(r => ({ key: r.code, text: r.description, value: r.description }))}
      onChange={(event, data: DropdownProps) => {
        const values = data.value as string[];
        onChange(values);
      }}
    />
  );
};

const DropDownRegions = ({
  selectedRegions,
  onChange,
  keringRegionList,
  loading,
}: DropdownRegionsProps): JSX.Element => {
  const { t } = useTranslation();
  const allRegionsDescription = t('users.allRegionsOptionDescription');

  return (
    <Dropdown
      compact
      loading={loading}
      css={style.dropDownIsRegionalManagerOf}
      placeholder={allRegionsDescription || ''}
      value={selectedRegions}
      selection
      multiple
      fluid
      clearable
      disabled={loading}
      options={Object.values(keringRegionList).map(r => ({ key: r.code, text: r.description, value: r.description }))}
      onChange={(event, data: DropdownProps) => {
        const values = data.value as string[];
        onChange(values);
      }}
    />
  );
};

const DropDownCountries = ({
  selectedCountries,
  onChange,
  keringCountryList,
  disabled,
  loading,
}: DropdownCountriesProps): JSX.Element => {
  const { t } = useTranslation();
  const allCountriesDescription = t('users.allCountriesOptionDescription');

  return (
    <Dropdown
      loading={loading}
      compact
      css={style.dropDownIsRegionalManagerOf}
      placeholder={allCountriesDescription || ''}
      value={selectedCountries.map(country => country.code)}
      selection
      multiple
      disabled={disabled || loading}
      fluid
      clearable
      options={Object.values(keringCountryList).map(country => ({
        key: country.code,
        text: country.description,
        value: country.code,
      }))}
      onChange={(event, data: DropdownProps) => {
        const values = data.value as string[];
        onChange(keringCountryList.filter(country => values.includes(country.code)));
      }}
    />
  );
};

const DropDownBrands = ({ error, selectedBrands, onChange }: DropdownBrandsProps): JSX.Element => {
  const { t } = useTranslation();
  const keringCode = 'KERING' as BrandCode;
  const keringDescription = t('users.keringOptionDescription');
  const options = useMemo(() => {
    const allBrandsItem: DropdownItemProps = {
      key: keringCode,
      text: keringDescription,
      value: keringCode,
    };
    const brandOptions = Object.values(BrandCode).map(brandCode => {
      const brand = toBrand(brandCode);
      return { key: brand.code, text: brand.description, value: brand.code };
    });

    if (selectedBrands.length === Object.values(BrandCode).length) {
      return brandOptions;
    }
    return [allBrandsItem, ...brandOptions];
  }, [keringDescription, selectedBrands.length]);

  return (
    <Dropdown
      compact
      css={style.dropDownBrands}
      placeholder={t('users.brands') as string}
      value={selectedBrands}
      error={error}
      selection
      multiple
      fluid
      clearable
      options={options}
      onChange={(event, data: DropdownProps) => {
        const values = data.value as BrandCode[];
        if (values.includes(keringCode)) {
          const codes = Object.values(BrandCode).filter(f => f !== keringCode);
          onChange(codes);
        } else {
          onChange(values);
        }
      }}
    />
  );
};
