import { emptyRegion, Region, toRegion } from '../../../shared/model/region.model';
import { Brand, emptyBrand, toAuditBrand } from '../../../shared/model/brand.model';
import { Contact, emptyContact, toContact } from '../../users/model/user.model';
import { AuditProposal, AuditProposalRequest, isProposalUnderReviewByAuditors, toAuditProposal } from './auditProposal';
import { checkRole, Principal, ROLES } from '../../auth/model/principal.model';
import { INTERNAL_AUDIT_PROPOSAL_STATUS } from './genericAuditProposal';
import { getUTCDateString } from '../../../core/utils';

export interface AuditCampaign {
  id: string;
  name: string;
  brand: Brand;
  region: Region;
  auditDateFrom: string;
  auditDateTo: string;
  owner: Contact;
  brandSchedulers: Contact[];
  distributionList: Contact[];
  proposals: AuditProposal[];
  deleted: boolean;
}

export interface AuditCampaignRequest {
  name: string;
  brand: string;
  region: string;
  owner: string;
  brandSchedulers: string[];
  distributionList: string[];
  proposals: AuditProposalRequest[];
}

export interface UpdateCampaignNameRequest {
  name: string;
}

export interface UpdateCampaignBrandSchedulersRequest {
  brandSchedulers: string[];
}

export interface UpdateCampaignDistributionListRequest {
  distributionList: string[];
}

export const emptyCampaign = (): AuditCampaign => ({
  id: '',
  name: '',
  brand: emptyBrand(),
  region: emptyRegion(),
  auditDateFrom: '',
  auditDateTo: '',
  owner: emptyContact(),
  brandSchedulers: [],
  distributionList: [],
  proposals: [],
  deleted: false,
});

export const toAuditCampaign = (o: Record<string, unknown>): AuditCampaign => {
  return {
    id: o.id as string,
    name: o.name as string,
    brand: toAuditBrand(o.brand as Record<string, unknown>),
    region: toRegion(o.region as Record<string, string>),
    auditDateFrom: o.auditDateFrom == null ? '' : getUTCDateString(o.auditDateFrom as string),
    auditDateTo: o.auditDateTo == null ? '' : getUTCDateString(o.auditDateTo as string),
    owner: toContact(o.owner as Record<string, unknown>),
    brandSchedulers: (o.brandSchedulers as Record<string, unknown>[]).map(toContact),
    distributionList: (o.distributionList as Record<string, unknown>[]).map(toContact),
    proposals: (o.proposals as Record<string, unknown>[])
      .map(toAuditProposal)
      .sort((p1, p2) => p1.proposedDateFrom.localeCompare(p2.proposedDateFrom)),
    deleted: o.deleted as boolean,
  };
};
const isCampaignOwner = (principal: Principal, campaign: AuditCampaign): boolean => {
  return checkRole(principal, ROLES.AUDITOR) && campaign.owner.email === principal.email;
};
export const isCampaignEditable = (principal: Principal, campaign: AuditCampaign): boolean => {
  return checkRole(principal, ROLES.AUDIT_ADMIN) || isCampaignOwner(principal, campaign);
};

export const isCampaignDeletable = (principal: Principal, campaign: AuditCampaign): boolean => {
  return checkRole(principal, ROLES.AUDIT_ADMIN) || isCampaignOwner(principal, campaign);
};

export const isCampaignApprovableByAuditorManager = (principal: Principal, campaign: AuditCampaign): boolean => {
  return (
    checkRole(principal, ROLES.AUDITOR_MANAGER) &&
    campaign.proposals.some(p => p.status === INTERNAL_AUDIT_PROPOSAL_STATUS.PROPOSED) &&
    !campaign.proposals.some(p => isProposalUnderReviewByAuditors(p))
  );
};
