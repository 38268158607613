/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, LabelGroup } from 'semantic-ui-react';
import { t } from 'i18next';
import style from './auditMultiToggle.style';
import { ModalPage } from '../../../shared/ModalPage';
import { FilterBadge } from '../../../shared/FilterBadge';
import { getPriorities, Priority } from '../model/assessmentDetail.model';
import { ToggleField } from './ToggleField';
import { SelectItem } from '../../../shared/ModalSelectField';
import { ModalMultiSelectField } from '../../../shared/ModalMultiSelectField';

interface AssessmentFieldProps {
  editable: boolean;
  title: string;
  defaultFindingsValue: boolean;
  defaultFollowUpValue?: boolean;
  defaultPriorityFilter: Priority[];
  showFindings: boolean;
  showFollowUp?: boolean;
  showPriorityFilter?: boolean;
  onFindingsChange?: (value: boolean) => void;
  onFollowUpChange?: (value: boolean) => void;
  onPriorityFilterChange?: (value: Priority[]) => void;
}

export const AuditMultiToggle = ({
  editable = false,
  title,
  defaultFindingsValue,
  defaultFollowUpValue = false,
  onFindingsChange,
  onFollowUpChange,
  onPriorityFilterChange,
  defaultPriorityFilter,
  showFindings = true,
  showFollowUp = false,
  showPriorityFilter = true,
}: AssessmentFieldProps): JSX.Element => {
  const [subProcessFilterWindowOpen, setSubProcessFilterWindowOpen] = useState(false);

  const [findingsValue, setFindingsValue] = useState(defaultFindingsValue);
  const [followUpValue, setFollowUpValue] = useState(defaultFollowUpValue);
  const [priorityFilter, setPriorityFilter] = useState<Priority[]>(defaultPriorityFilter);

  useEffect(() => {
    setFindingsValue(defaultFindingsValue);
    setFollowUpValue(defaultFollowUpValue);
    setPriorityFilter(defaultPriorityFilter);
  }, [defaultFindingsValue, defaultFollowUpValue, defaultPriorityFilter]);

  const onApplyFilters = useCallback(() => {
    if (onFindingsChange != null) {
      onFindingsChange(findingsValue);
    }
    if (onFollowUpChange != null) {
      onFollowUpChange(followUpValue);
    }
    if (onPriorityFilterChange != null) {
      onPriorityFilterChange(priorityFilter);
    }
    setSubProcessFilterWindowOpen(false);
  }, [findingsValue, followUpValue, onFindingsChange, onFollowUpChange, onPriorityFilterChange, priorityFilter]);

  const onClearFilters = useCallback(() => {
    if (onFindingsChange != null) {
      onFindingsChange(false);
    }
    if (onFollowUpChange != null) {
      onFollowUpChange(false);
    }
    if (onPriorityFilterChange != null) {
      onPriorityFilterChange([]);
    }
    setSubProcessFilterWindowOpen(false);
  }, [onFindingsChange, onFollowUpChange, onPriorityFilterChange]);

  const getAppliedFilters = useCallback(() => {
    const appliedFilters: { value?: any; label: string }[] = [];

    if (defaultFindingsValue) {
      appliedFilters.push({ label: t('assessment.audit.findingsAndToDo') });
    }

    if (defaultFollowUpValue) {
      appliedFilters.push({ label: t('assessment.audit.followUp') });
    }

    if (defaultPriorityFilter.length > 0) {
      appliedFilters.push({ label: t('assessment.audit.priority'), value: defaultPriorityFilter.join(', ') });
    }

    return appliedFilters;
  }, [defaultFindingsValue, defaultFollowUpValue, defaultPriorityFilter]);

  return (
    <>
      <div css={editable ? style.editableField : style.field}>
        <div css={style.titleContainer} onClick={event => setSubProcessFilterWindowOpen(true)}>
          {getAppliedFilters().length > 0 ? (
            <div css={style.badgeGroup}>
              <LabelGroup circular>
                {getAppliedFilters().map(f => (
                  <FilterBadge value={f.value} label={f.label} />
                ))}
              </LabelGroup>
            </div>
          ) : (
            <div css={style.noAppliedFiltersLabel}>{t('assessment.audit.filters')}</div>
          )}
          <Button css={style.filterButton} compact icon='filter' />
        </div>
      </div>
      {subProcessFilterWindowOpen && (
        <ModalPage onClose={() => setSubProcessFilterWindowOpen(false)} title={t('assessment.audit.filters') || ''}>
          <div css={style.toggleContainer}>
            {showFindings && (
              <ToggleField
                icon='clipboard list'
                defaultValue={findingsValue}
                title={t('assessment.audit.showOnlyFindingsAndToDo') || ''}
                label={t('assessment.audit.showOnlyFindingsAndToDo') || ''}
                onChange={value => {
                  if (onFindingsChange != null && editable) setFindingsValue(value);
                }}
                editable={editable}
              />
            )}
            {showFollowUp && (
              <ToggleField
                icon='clipboard check'
                defaultValue={followUpValue}
                title={t('assessment.audit.showOnlyFollowUp') || ''}
                label={t('assessment.audit.showOnlyFollowUp') || ''}
                onChange={value => {
                  if (onFollowUpChange != null && editable) setFollowUpValue(value);
                }}
                editable={editable}
              />
            )}

            {showPriorityFilter && (
              <ModalMultiSelectField
                icon='angle double up'
                defaultValue={
                  priorityFilter.map(v => ({
                    code: v,
                    description: v,
                  })) as SelectItem[]
                }
                list={
                  getPriorities().map(v => ({
                    code: v,
                    description: v,
                  })) as SelectItem[]
                }
                title={t('assessment.audit.priority') || ''}
                editable={editable}
                onUpdateValue={(selectedItems: SelectItem[]) => {
                  setPriorityFilter(selectedItems.map(b => b.code as Priority));
                }}
              />
            )}

            <div css={style.filterButtonsContainer}>
              <Button
                css={style.applyFiltersButton}
                content={t('assessment.audit.clearFilters')}
                onClick={onClearFilters}
                icon='eraser'
              />
              <Button
                css={style.applyFiltersButton}
                content={t('assessment.audit.applyFilters')}
                onClick={onApplyFilters}
                icon='filter'
              />
            </div>
          </div>
        </ModalPage>
      )}
    </>
  );
};
