/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { AuditingResult, ResultStatus, SubProcessResult } from '../model/assessmentDetail.model';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { ModalPage } from '../../../shared/ModalPage';
import { CheckList } from './CheckList';
import { ResultItemField } from './ResultItemField';
import { STATUS } from '../model/assessmentStatus';

interface ResultItemProps {
  subProcessCode: string;
  result: AuditingResult | SubProcessResult;
  subProcessInfo: string;
}

export const ResultItem = ({ subProcessCode, subProcessInfo, result }: ResultItemProps): JSX.Element => {
  const { t } = useTranslation();
  const user = useSelector(selectPrincipal);
  const [isReadonlyCheckListOpen, setReadonlyCheckListOpen] = useState(false);
  const getAuditorName = () =>
    !('auditor' in result) ? '' : user.email !== `${result.auditor.email}:` ? `${result.auditor.name}:` : 'Me:';
  return isReadonlyCheckListOpen ? (
    <ModalPage onClose={() => setReadonlyCheckListOpen(false)} title={`${getAuditorName()} ${subProcessCode}`}>
      <CheckList result={result} subProcessInfo={subProcessInfo} assessmentStatus={STATUS.MERGING} />
    </ModalPage>
  ) : (
    <div css={itemColor(result.status)}>
      <ResultItemField
        onClick={() => setReadonlyCheckListOpen(true)}
        iconName='search'
        value={`${getAuditorName()} ${t(`assessment.audit.result.status.${result.status}`)}`}
        status={result.status}
        priority={result.priority}
      />
    </div>
  );
};

const itemColor = (status: ResultStatus) => {
  return css`
    && div {
     i{ color: ${status === ResultStatus.PASSED ? 'var(--saa-success-dark)' : 'var(--saa-warning-dark)'}; }
      font-weight: 500;
    }
  `;
};
