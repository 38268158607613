/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { SerializedStyles } from '@emotion/react';
import { AdUser } from '../model/user.model';
import style from './adUserDropdownField.style';
import api from '../utils/api';
import { BrandCode } from '../../../shared/model/brand.model';

interface AdUserDropdownProps {
  onChange: (adUser?: AdUser) => void;
  css?: SerializedStyles;
  error?: boolean;
  cornerIcon?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: AdUser;
  text?: 'email' | 'fullName';
  disabled?: boolean;
  clearable?: boolean;
  assessmentBrand?: BrandCode;
}

export const AdUserDropdownField = ({
  error = false,
  onChange,
  cornerIcon,
  value,
  disabled = false,
  css,
  placeholder = '',
  defaultValue,
  text = 'email',
  clearable = false,
  assessmentBrand,
}: AdUserDropdownProps): JSX.Element => {
  const [adUsers, setAdUsers] = useState<AdUser[]>(defaultValue ? [defaultValue] : []);
  const [isFetching, setIsFetching] = useState(false);
  const [currentValue, setCurrentValue] = useState<number | string | undefined>(value);
  const cssStyle = css ?? cornerIcon ? style.adUserDropdownCornerIcon : style.adUserDropdown;

  const generateItemOptions = (users: AdUser[]): DropdownItemProps[] => {
    const generateItem = (user: AdUser): DropdownItemProps => {
      const fullName = `${user.firstName} ${user.familyName}`;
      return {
        key: user.email,
        value: user.email,
        text: `${text === 'fullName' ? fullName : user.email}`,
        content: (
          <div>
            <div css={style.dropdownItemName}>{fullName}</div>
            <div>{user.email}</div>
          </div>
        ),
      };
    };

    return users.map(generateItem);
  };

  const [options, setOptions] = useState<DropdownItemProps[]>(generateItemOptions(defaultValue ? [defaultValue] : []));

  const searchAdUsers = useDebouncedCallback(async (userToSearch: string) => {
    if (userToSearch.trim() === '') {
      setAdUsers([]);
      setOptions([]);
      return;
    }
    if (userToSearch.trim().length < 3) {
      return;
    }
    setIsFetching(true);
    try {
      const result =
        assessmentBrand != null
          ? await api.getMergedUsers(userToSearch, assessmentBrand)
          : await api.getAdUsers(userToSearch);
      if (result.length > 0) {
        setAdUsers(result);
        setOptions(generateItemOptions(result));
      }
    } catch {
      setAdUsers([]);
      setOptions([]);
    } finally {
      setIsFetching(false);
    }
  }, 300);

  useEffect(() => {
    if (value === '') {
      setCurrentValue('');
      setOptions([]);
    }
  }, [value]);

  return (
    <Dropdown
      value={currentValue}
      icon={cornerIcon}
      css={cssStyle}
      selection
      fluid
      error={error}
      search={() => options}
      options={options}
      placeholder={placeholder}
      defaultValue={defaultValue?.email}
      onChange={(e, { value: optionValue }) => {
        const adUser = adUsers.find(u => u.email === optionValue);
        setCurrentValue(optionValue as string);
        onChange(adUser);
        if (optionValue === '') {
          setOptions([]);
        }
      }}
      onSearchChange={(e, { searchQuery }) => {
        searchAdUsers(searchQuery);
        if (searchQuery.trim() === '') {
          onChange({ email: '', firstName: '', familyName: '', validBrands: [] } as AdUser);
        }
      }}
      selectOnBlur={false}
      onBlur={() => {
        if (currentValue === '') {
          setOptions([]);
        }
      }}
      disabled={disabled}
      loading={isFetching}
      clearable={clearable}
    />
  );
};
