import { css, SerializedStyles } from '@emotion/react';

const container = css`
  overflow: auto;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const cardsContainer = css`
  height: 100%;
  overflow: auto;
  @media (min-width: 1200px) {
    padding-right: 5px;
  }
`;

const group = css`
  display: flex;
  overflow: auto;
  @media (min-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const sectionTitle = (): SerializedStyles => {
  const border = `&& {box-shadow: inset 0 -2px 0 0 var(--saa-primary-color-80) }`;
  return css`
    && {
      margin: 10px 0 10px;
      padding-bottom: 3px;
      ${border}
    }
  `;
};

const header = css`
  display: flex;
  @media (min-width: 1200px) {
    padding-bottom: 5px;
  }
`;

const searchBoxContainer = css`
  flex: 1;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  height: max-content;
`;

const notFound = css`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
`;

export default {
  group,
  container,
  sectionTitle,
  cardsContainer,
  header,
  searchBoxContainer,
  notFound,
};
