import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../core/store';
import api from '../utils/api';
import { AssessmentReportResult } from '../model/assessmentReportResult';
import {
  AssessmentReportFilters,
  covertFiltersInQueryParams,
  emptyAssessmentReportFilters,
} from '../model/assessmentReportQueryParams';
import { Brand } from '../../../shared/model/brand.model';
import { Store } from '../../../shared/model/store.model';
import { Priority } from '../../assessments/model/assessmentDetail.model';

export type SortOrder = 'ascending' | 'descending';

export interface SortField {
  sortColumn: string;
  sortOrder?: SortOrder;
}

export interface Pagination {
  limit: number;
  page: number;
}

interface AssessmentReportResultsSliceState {
  results: AssessmentReportResult[];
  isFetching: boolean;
  error: string;
  filters: AssessmentReportFilters;
  sort: SortField;
  pagination: Pagination;
  statuses: string[];
  brands: Brand[];
  regions: string[];
  countries: string[];
  cities: string[];
  stores: Store[];
  showResults: boolean;
  followUpStatuses: string[];
  priorities: string[];
}

const initialState: AssessmentReportResultsSliceState = {
  results: [],
  isFetching: false,
  error: '',
  filters: emptyAssessmentReportFilters,
  sort: { sortColumn: 'locationName', sortOrder: 'ascending' },
  pagination: { page: 1, limit: 30 },
  statuses: [],
  brands: [],
  regions: [],
  countries: [],
  cities: [],
  stores: [],
  showResults: false,
  followUpStatuses: [],
  priorities: [],
};

export const assessmentReportResultsSlice = createSlice({
  name: 'assessmentReportResults',
  initialState,
  reducers: {
    startFetch: (state: Draft<AssessmentReportResultsSliceState>) => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<AssessmentReportResult[]>
    ) => ({
      ...state,
      isFetching: false,
      results: payload,
      error: '',
    }),
    setFilter: (
      state: Draft<AssessmentReportResultsSliceState>,
      { payload }: PayloadAction<AssessmentReportFilters>
    ) => ({
      ...state,
      filters: payload,
    }),
    setShowResults: (state: Draft<AssessmentReportResultsSliceState>, { payload }: PayloadAction<boolean>) => ({
      ...state,
      showResults: payload,
    }),
    setPagination: (state: Draft<AssessmentReportResultsSliceState>, { payload }: PayloadAction<Pagination>) => {
      return {
        ...state,
        pagination: payload,
      };
    },
    setSort: (state: Draft<AssessmentReportResultsSliceState>, { payload }: PayloadAction<SortField>) => {
      return {
        ...state,
        sort: payload,
      };
    },
    finishStatusesFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      statuses: [...action.payload],
      error: '',
    }),
    finishBrandFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<Brand[]>) => ({
      ...state,
      isFetching: false,
      brands: [...action.payload],
      error: '',
    }),
    finishRegionFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      regions: [...action.payload],
      error: '',
    }),
    finishCountriesFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      countries: [...action.payload],
      error: '',
    }),
    finishCitiesFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string[]>) => ({
      ...state,
      isFetching: false,
      cities: [...action.payload],
      error: '',
    }),
    finishStoresFetch: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<Store[]>) => ({
      ...state,
      isFetching: false,
      stores: [...action.payload],
      error: '',
    }),
    finishFollowUpStatusesFetch: (
      state: Draft<AssessmentReportResultsSliceState>,
      action: PayloadAction<string[]>
    ): AssessmentReportResultsSliceState => ({
      ...state,
      isFetching: false,
      followUpStatuses: [...action.payload],
      error: '',
    }),
    finishPrioritiesFetch: (
      state: Draft<AssessmentReportResultsSliceState>,
      action: PayloadAction<string[]>
    ): AssessmentReportResultsSliceState => ({
      ...state,
      isFetching: false,
      priorities: [...action.payload],
      error: '',
    }),
    httpError: (state: Draft<AssessmentReportResultsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const {
  startFetch,
  finishFetch,
  setFilter,
  setShowResults,
  setPagination,
  setSort,
  finishStatusesFetch,
  finishBrandFetch,
  finishRegionFetch,
  finishCountriesFetch,
  finishCitiesFetch,
  finishStoresFetch,
  finishFollowUpStatusesFetch,
  finishPrioritiesFetch,
  httpError,
} = assessmentReportResultsSlice.actions;

export default assessmentReportResultsSlice.reducer;

export const fetchAssessmentReportResults =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const results = await api.getAssessmentReportResults(covertFiltersInQueryParams(filters));
      dispatch(finishFetch(results));
      dispatch(setFilter(filters));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const resetAssessmentReportResults = (): AppThunk => async (dispatch: any) => {
  dispatch(startFetch());
  try {
    dispatch(finishFetch([]));
    dispatch(setFilter(emptyAssessmentReportFilters));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const onChangePage =
  (page: number): AppThunk =>
  (dispatch, state) => {
    const { pagination } = state().assessmentReportResults;
    if (page !== pagination.page) {
      dispatch(setPagination({ ...pagination, page }));
    }
  };

export const onSort =
  (clickedColumn: string): AppThunk =>
  (dispatch, state) => {
    const { sortColumn, sortOrder } = state().assessmentReportResults.sort;
    const { pagination } = state().assessmentReportResults;

    let newOrder: SortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
    if (sortColumn !== clickedColumn) {
      newOrder = 'ascending';
    }

    dispatch(setPagination({ ...pagination, page: 1 }));
    dispatch(setSort({ sortColumn: clickedColumn, sortOrder: newOrder }));
  };

export const fetchStatuses =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const statuses: string[] = await api.getStatuses(covertFiltersInQueryParams(filters));
      dispatch(finishStatusesFetch(statuses));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchBrands =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const brands: Brand[] = await api.getBrands(covertFiltersInQueryParams(filters));
      dispatch(finishBrandFetch(brands));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchRegions =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const regions: string[] = await api.getRegions(covertFiltersInQueryParams(filters));
      dispatch(finishRegionFetch(regions));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchCountries =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const countries: string[] = await api.getCountries(covertFiltersInQueryParams(filters));
      dispatch(finishCountriesFetch(countries));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchCities =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const cities: string[] = await api.getCities(covertFiltersInQueryParams(filters));
      dispatch(finishCitiesFetch(cities));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchStores =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const stores: Store[] = await api.getStores(covertFiltersInQueryParams(filters));
      dispatch(finishStoresFetch(stores));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchFollowUpStatuses =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const statuses: string[] = await api.getFollowUpStatuses(covertFiltersInQueryParams(filters));
      dispatch(finishFollowUpStatusesFetch(statuses));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchPriorities =
  (filters: AssessmentReportFilters): AppThunk =>
  async (dispatch: any) => {
    dispatch(startFetch());
    try {
      const statuses: string[] = await api.getPriorities(covertFiltersInQueryParams(filters));
      dispatch(finishPrioritiesFetch(statuses));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectIsFetching = (state: RootState): boolean => state.assessmentReportResults.isFetching;

export const selectFilters = (state: RootState): AssessmentReportFilters => state.assessmentReportResults.filters;

export const selectSort = (state: RootState) => state.assessmentReportResults.sort;
export const selectPagination = (state: RootState) => state.assessmentReportResults.pagination;
export const selectPaginationLimit = (state: RootState) => state.assessmentReportResults.pagination.limit;

export const selectResults = (state: RootState): AssessmentReportResult[] => [...state.assessmentReportResults.results];

export const selectSortedAndFilteredResults = createSelector(
  selectResults,
  selectSort,
  selectFilters,
  (results, sort, filters) => {
    const filteredResults = results.filter(
      r =>
        (filters.priorities.length === 0 && r.subProcessResultPriority !== Priority.PASS) ||
        filters.priorities.includes(r.subProcessResultPriority.valueOf())
    );
    return sort.sortOrder === 'descending'
      ? _.sortBy(filteredResults, [sort.sortColumn]).reverse()
      : _.sortBy(filteredResults, [sort.sortColumn]);
  }
);

export const selectSortedAndPaginatedResults = createSelector(
  selectSortedAndFilteredResults,
  selectPagination,
  (sortedData, pagination) => {
    const startIndex = pagination.page * pagination.limit - pagination.limit;
    const endIndex = startIndex + pagination.limit;
    return sortedData?.slice(startIndex, endIndex);
  }
);
export const selectTotalPagesAndCount = createSelector(
  selectSortedAndFilteredResults,
  selectPaginationLimit,
  (filteredData, paginationLimit) => {
    return {
      totalPages: filteredData.length > 0 ? Math.ceil(filteredData.length / paginationLimit) : 1,
      totalCount: filteredData?.length || 0,
    };
  }
);

export const selectStatuses = (state: RootState) => state.assessmentReportResults.statuses;
export const selectBrands = (state: RootState) => state.assessmentReportResults.brands;
export const selectRegions = (state: RootState) => state.assessmentReportResults.regions;
export const selectCountries = (state: RootState) => state.assessmentReportResults.countries;
export const selectCities = (state: RootState) => state.assessmentReportResults.cities;
export const selectStores = (state: RootState) => state.assessmentReportResults.stores;
export const selectShowResults = (state: RootState) => state.assessmentReportResults.showResults;
export const selectFollowUpStatuses = (state: RootState) => state.assessmentReportResults.followUpStatuses;
export const selectPriorities = (state: RootState) => state.assessmentReportResults.priorities;
