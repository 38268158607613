/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, LabelGroup } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import style from './assessmentFilter.style';
import { selectFilters, selectIsOpen, setFilters, toggleFilters } from '../../store/assessmentsSlice';
import { Brand } from '../../../../shared/model/brand.model';
import { AssessmentFilterInterface, emptyAssessmentFilterInterface } from '../../model/assessmentFilter.model';
import { YearMonthRangeField } from '../../../../shared/YearMonthRangeField';
import { useCtrlEnterToRunCallback } from '../../../../core/utils';
import { useAppDispatch } from '../../../../core/store';
import { Assessment, Auditor } from '../../model/assessment';
import { AssessmentStatusMultiSelectField } from './AssessmentStatusMultiSelectField';
import { AssessmentBrandMultiSelectField } from './AssessmentBrandMultiSelectField';
import { AssessmentRegionMultiSelectField } from './AssessmentRegionMultiSelectField';
import { AssessmentCountryMultiSelectField } from './AssessmentCountryMultiSelectField';
import { AssessmentAuditorMultiSelectField } from './AssessmentAuditorMultiSelectField';
import { FilterBadge } from '../../../../shared/FilterBadge';

interface AssessmentFilterProps {
  items: Assessment[];
}

const AssessmentFilter = ({ items }: AssessmentFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters = useSelector(selectFilters);

  const [statuses, setStatuses] = useState<string[]>(filters.status);
  const [brands, setBrands] = useState<Brand[]>(filters.brands);
  const [regions, setRegions] = useState<string[]>(filters.regions);
  const [countries, setCountries] = useState<string[]>(filters.countries);
  const [auditors, setAuditors] = useState<Auditor[]>(filters.auditors);
  const [monthFrom, setMonthFrom] = useState<number | undefined>(filters.monthFrom);
  const [monthTo, setMonthTo] = useState<number | undefined>(filters.monthTo);
  const [yearFrom, setYearFrom] = useState<number | undefined>(filters.yearFrom);
  const [yearTo, setYearTo] = useState<number | undefined>(filters.yearTo);

  const extractedStatuses = Array.from(new Set(items.map(item => item.status.code)));
  const extractedBrands = Array.from(new Set(items.map(item => item.store.brand.code)))
    .map(code => items.find(item => item.store.brand.code === code)?.store.brand as Brand)
    .filter(Boolean);
  const extractedRegions = Array.from(new Set(items.map(item => item.store.keringRegion)));
  const extractedCountries = Array.from(new Set(items.map(item => item.store.keringCountry.description)));
  const allAuditors = items.flatMap(item => item.auditors as Auditor[]);
  const extractedAuditors = Array.from(new Set(allAuditors.map(auditor => auditor.email))).map(
    email => allAuditors.find(auditor => auditor.email === email) as Auditor
  );

  const [availableStatuses] = useState<string[]>(extractedStatuses);
  const [availableBrands] = useState<Brand[]>(extractedBrands);
  const [availableRegions] = useState<string[]>(extractedRegions);
  const [availableCountries, setAvailableCountries] = useState<string[]>(extractedCountries);
  const [availableAuditors] = useState<Auditor[]>(extractedAuditors);

  useEffect(() => {
    const localCountries = Array.from(
      new Set(
        items
          .filter(item => (regions && regions.length > 0 ? regions.includes(item.store.keringRegion) : false))
          .map(item => item.store.keringCountry.description)
      )
    );

    setAvailableCountries(localCountries);
  }, [items, regions]);

  const getFilters = useCallback(
    (): AssessmentFilterInterface => ({
      status: statuses || [],
      brands,
      regions,
      countries,
      auditors,
      monthFrom,
      monthTo,
      yearFrom,
      yearTo,
    }),
    [statuses, brands, regions, countries, auditors, monthFrom, monthTo, yearFrom, yearTo]
  );

  const search = useCallback(() => {
    dispatch(toggleFilters(false));
    dispatch(setFilters(getFilters()));
  }, [dispatch, getFilters]);

  const clear = useCallback(() => {
    setStatuses([]);
    setBrands([]);
    setRegions([]);
    setCountries([]);
    setAuditors([]);
    setMonthFrom(undefined);
    setMonthTo(undefined);
    setYearFrom(undefined);
    setYearTo(undefined);
  }, []);

  useCtrlEnterToRunCallback(search);

  return (
    <div css={style.filtersContainer}>
      <div>
        <AssessmentStatusMultiSelectField
          options={availableStatuses}
          defaultValue={statuses}
          editable
          onUpdate={selectedValues => {
            setStatuses(selectedValues);
          }}
        />
        <AssessmentBrandMultiSelectField
          options={availableBrands}
          defaultValue={brands}
          editable
          onUpdate={selectedValues => {
            setBrands(selectedValues);
          }}
        />
        <AssessmentRegionMultiSelectField
          options={availableRegions}
          defaultValue={regions}
          editable
          onUpdate={selectedValues => {
            setRegions(selectedValues);
            setCountries(emptyAssessmentFilterInterface.countries);
          }}
        />
        <AssessmentCountryMultiSelectField
          options={availableCountries}
          defaultValue={countries}
          editable={regions.length > 0}
          onUpdate={selectedValues => {
            setCountries(selectedValues);
          }}
        />
        <AssessmentAuditorMultiSelectField
          options={availableAuditors}
          defaultValue={auditors}
          editable
          onUpdate={selectedValues => {
            setAuditors(selectedValues);
          }}
        />
        <YearMonthRangeField
          monthFrom={monthFrom}
          monthTo={monthTo}
          yearFrom={yearFrom}
          yearTo={yearTo}
          title={t('assessment.archive.filters.yearMonthRange')}
          editable
          onEdited={(yf, mf, yt, mt) => {
            setYearFrom(yf);
            setMonthFrom(mf);
            setYearTo(yt);
            setMonthTo(mt);
          }}
          clearable
        />
      </div>

      <div css={style.buttonsContainer}>
        <Button css={style.button} onClick={clear} icon='eraser' content={t('assessment.archive.clear')} />
        <Button css={style.button} onClick={search} icon='search' content={t('assessment.archive.search')} />
      </div>
    </div>
  );
};

const AssessmentFilterButton = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isFilterOpen = useSelector(selectIsOpen);

  const onFilterButtonClick = useCallback(() => {
    dispatch(toggleFilters(!isFilterOpen));
  }, [dispatch, isFilterOpen]);

  return (
    <div css={style.container}>
      <Button css={style.filterButton} onClick={onFilterButtonClick}>
        <Icon name='filter' />
        <span>{t('assessment.archive.filtersText')}</span>
      </Button>
    </div>
  );
};

const FiltersBadgesContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters = useSelector(selectFilters);
  const [numberOfFiltersApplied, setNumberOfFiltersApplied] = useState<number>(0);

  const getFiltersArray = useCallback(() => {
    const filtersBadges = {
      status: filters.status.map(s => t(`assessment.status.${s}`)).join(', '),
      brands: filters.brands.map(b => b.description).join(', '),
      regions: filters.regions.join(', '),
      countries: filters.countries.join(', '),
      auditors: filters.auditors.map(a => a.name).join(', '),
      yearFrom: filters.yearFrom,
      monthFrom: filters.monthFrom,
      yearTo: filters.yearTo,
      monthTo: filters.monthTo,
    };

    return Object.keys(filtersBadges)
      .map(k => {
        const realValue = (filters as any)[k];

        return {
          show: !_.isEqual(realValue, (emptyAssessmentFilterInterface as any)[k]),
          field: k,
          description: (filtersBadges as any)[k],
          label: t(`assessment.archive.filters.${k}`),
        };
      })
      .filter(f => f.show);
  }, [filters, t]);

  const isFilterOpen = useSelector(selectIsOpen);

  const onFilterButtonClick = useCallback(() => {
    dispatch(toggleFilters(!isFilterOpen));
  }, [dispatch, isFilterOpen]);

  useEffect(() => {
    const sum = Object.entries(filters).reduce((acc, [key, property]) => {
      if (Array.isArray(property)) {
        return acc + property.length;
      }

      if (typeof property === 'object' && property !== null) {
        return acc + Object.keys(property).length;
      }
      return acc;
    }, 0);

    setNumberOfFiltersApplied(sum);
  }, [filters]);

  return (
    <div css={style.badgeGroup}>
      <div css={style.badges}>
        <LabelGroup circular>
          {getFiltersArray().map(f => (
            <FilterBadge key={f.label} value={f.description} label={f.label} />
          ))}
        </LabelGroup>
      </div>
      <div css={style.filterButtonContainer}>
        <Button css={style.filterButton} onClick={onFilterButtonClick}>
          <div css={style.icon}>
            <Icon name='filter' />
          </div>
          <span>{t('assessment.archive.filtersText')}</span>
        </Button>
        {numberOfFiltersApplied > 0 && (
          <Label css={style.filterButtonBadge} floating circular>
            {numberOfFiltersApplied}
          </Label>
        )}
      </div>
    </div>
  );
};

export { AssessmentFilter, AssessmentFilterButton, FiltersBadgesContainer };
