import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

// ---------- Filter ----------

const container = css`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  padding: 5px;
`;

// ---------- Button ----------

const badgeGroup = css`
  flex: 1;
  display: flex;
  @media (min-width: 540px) {
    margin: 0px 10px 0 5px;
    border-bottom: 1px solid var(--saa-primary-color-60);
    padding-bottom: 5px;
    justify-content: space-between;
    min-height: 37px;
    height: max-content;
    align-items: center;
  }
  .ui.labels {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 5px;
  }
`;

const badges = css`
  @media (max-width: 540px) {
    display: none;
  }
`;

const filterButton = css`
  && {
    @media (min-width: 540px) {
      display: flex;
      ${globalStyle.primaryButton};
    }
    @media (max-width: 540px) {
      && {
        color: var(--saa-white-color);
        background-color: var(--saa-primary-color);
        cursor: pointer;
        margin-right: 5px;
        &&:hover,
        &&:focus {
          color: var(--saa-primary-color-40);
          background-color: var(--saa-primary-color);
        }
      }
      width: 50px;
      height: 100%;
      span {
        display: none;
      }
    }
  }
`;

const buttonsContainer = css`
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
  padding-top: 25px;
  padding-bottom: 15px;
  position: sticky;
  bottom: 0;
  background-color: var(--saa-primary-color-5);
  gap: 5px;
`;

const button = css`
  ${globalStyle.primaryButton};
  width: 120px;
  @media (max-width: 540px) {
    flex: 1;
  }
`;

const filterButtonBadge = css`
  &&& {
    background-color: var(--saa-blue-60);
    color: var(--saa-white-color);
    top: unset;
    right: unset;
    bottom: -6px;
    left: 16px;
    @media (min-width: 540px) {
      display: none;
    }
  }
`;

const filterButtonContainer = css`
  cursor: pointer;
  position: relative;
`;

const filtersContainer = css`
  margin: auto;
  display: flex;
  max-width: 600px;
  width: 100%;
  height: max-content;
  flex-direction: column;
  padding: 15px 15px 0;
  background-color: var(--saa-primary-color-5);
  border-radius: 4px;

  @media (max-width: 540px) {
    height: 100%;
  }
`;

const icon = css`
  && {
    margin: 0;
  }
`;

export default {
  container,
  filtersContainer,
  filterButton,
  buttonsContainer,
  button,
  badgeGroup,
  filterButtonBadge,
  badges,
  filterButtonContainer,
  icon,
};
