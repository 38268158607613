import { Worksheet } from 'exceljs';
import { t } from 'i18next';
import XLSXUtils from '../../../core/services/XLSXUtils';
import { AssessmentReportResult } from '../model/assessmentReportResult';
import { checkRoles, Principal, ROLES } from '../../auth/model/principal.model';

const includeColumn = (columnName: string, user: Principal) =>
  !['status', 'subProcessScore', 'failedScorePercentage', 'rating', 'successScorePercentage'].includes(columnName) ||
  checkRoles(user, [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER]);

export const exportAssessmentReportResultsXls = (results: AssessmentReportResult[], title: string, user: Principal) => {
  const columnNames = [
    'status',
    'brand',
    'region',
    'country',
    'city',
    'hfmCode',
    'jdaCode',
    'locationName',
    'year',
    'month',
    'processCode',
    'processDescription',
    'subProcessCode',
    'subProcessDescription',
    'subProcessResultFindings',
    'subProcessResultPriority',
    'subProcessResultRecommendation',
    'subProcessScore',
    'failedScorePercentage',
    'rating',
    'successScorePercentage',
    'actionPlanDescription',
    'actionPlanSupervisor',
    'actionPlanSupervisorRole',
    'actionPlanDueDate',
    'followUpStatus',
    'followUpResult',
    'followUpDescription',
    'followUpDueDate',
  ]
    .filter(c => includeColumn(c, user))
    .map(c => t(`assessment.report.columns.${c}`));

  const rowsData = results
    .map(r => ({
      status: t(`assessment.status.${r.status}`),
      brand: r.brand.description,
      region: r.region,
      country: r.country,
      city: r.city,
      hfmCode: r.hfmCode,
      jdaCode: r.jdaCode,
      locationName: r.locationName,
      year: r.year.toString(),
      month: r.month.toString(),
      processCode: r.processCode,
      processDescription: r.processDescription,
      subProcessCode: r.subProcessCode,
      subProcessDescription: r.subProcessDescription,
      subProcessResultFindings: r.subProcessResultFindings,
      subProcessResultPriority: r.subProcessResultPriority,
      subProcessResultRecommendation: r.subProcessResultRecommendation,
      subProcessScore: r.subProcessScore.toString(),
      failedScorePercentage: `${(r.failedScorePercentage * 100).toFixed(2)}%`,
      rating: r.rating,
      successScorePercentage: `${(r.successScorePercentage * 100).toFixed(2)}%`,
      actionPlanDescription: r.actionPlanDescription,
      actionPlanSupervisor: r.actionPlanSupervisor,
      actionPlanSupervisorRole: r.actionPlanSupervisorRole,
      actionPlanDueDate: r.actionPlanDueDate || '',
      followUpStatus: r.followUpStatus,
      followUpResult: r.followUpResult,
      followUpDescription: r.followUpDescription,
      followUpDueDate: r.followUpDueDate,
    }))
    .map(r =>
      Object.keys(r)
        .filter(k => includeColumn(k, user))
        .map(k => (r as any)[k])
    );

  const { workbook, worksheet } = XLSXUtils.createXlsSingleSheet(columnNames, rowsData, 'Assessment report results');

  XLSXUtils.adjustColumnWidth(worksheet);
  XLSXUtils.wrapColumnsText(worksheet);
  styleHeader(worksheet);
  XLSXUtils.downloadXls(workbook, title);
};

const styleHeader = (worksheet: Worksheet) => {
  const header = worksheet.getRow(1);
  header.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FF191970',
    },
  };
  header.alignment = { vertical: 'middle', horizontal: 'left' };
  header.font = { name: 'Arial', size: 10, bold: true, color: { argb: 'FFFFFFFF' } };
};
