import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { Region } from '../../../shared/model/region.model';

interface KeringRegionsSliceState {
  data: Region[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
}

export const initialState: KeringRegionsSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
};

const keringRegionsSlice = createSlice({
  name: 'keringRegions',
  initialState,
  reducers: {
    startFetch: (state: Draft<KeringRegionsSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<KeringRegionsSliceState>, { payload }: PayloadAction<Region[]>) => {
      return {
        isFetching: false,
        data: [...payload],
        error: '',
        isValidCache: true,
      };
    },
    errorFetch: (state: Draft<KeringRegionsSliceState>, { payload }: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payload,
    }),
    invalidateCache: (state: Draft<KeringRegionsSliceState>) => ({ ...state, isValidCache: false }),
  },
});
export const { startFetch, finishFetch, errorFetch, invalidateCache } = keringRegionsSlice.actions;

export const fetchKeringRegions = () => async (dispatch: any, state: any) => {
  if (!state().keringRegions.isFetching) {
    dispatch(startFetch());
    try {
      const keringRegions = (await api.getKeringRegions()).sort((r1, r2) =>
        r1.description.localeCompare(r2.description)
      );
      dispatch(finishFetch(keringRegions));
    } catch (error) {
      dispatch(errorFetch(JSON.stringify(error)));
    }
  }
};

export const selectKeringRegions = (state: any) => state.keringRegions.data as Region[];

export const selectIsFetching = (state: any) => state.keringRegions.isFetching;

export default keringRegionsSlice.reducer;
