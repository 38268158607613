/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Action, AnyAction, combineReducers, configureStore, createAction, ThunkAction } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { useDispatch } from 'react-redux';

import principalReducer from '../features/auth/store/principalSlice';
import assessmentsReducer from '../features/assessments/store/assessmentsSlice';
import assessmentsFilterReducer from '../features/assessments/store/filterSlice';
import { deleteJwtToken } from './services/webStorageService';
import onCreationAssessmentReducer from '../features/assessments/store/onCreationSlice';
import availableStoresReducer from '../features/assessments/store/availableStoresSlice';
import auditorsReducer from '../features/assessments/store/auditorsSlice';
import brandSchedulerReducer from '../features/assessmentsPlanning/store/brandSchedulersSlice';
import assessmentReducer from '../features/assessments/store/assessmentDetailSlice';
import auditorResultsReducer from '../features/assessments/store/auditorResultsSlice';
import auditorUpdateRequestsReducer from '../features/assessments/store/auditorUpdateRequestsSlice';
import usersReducer from '../features/users/store/usersSlice';
import assessmentsArchiveReducer from '../features/assessments/store/assessmentsArchiveSlice';
import auditCampaignsReducer from '../features/assessmentsPlanning/store/auditCampaignsSlice';
import auditProposalWizardReducer from '../features/assessmentsPlanning/store/auditProposalWizardSlice';
import auditProposalDetailReducer from '../features/assessmentsPlanning/store/auditProposalDetailSlice';
import auditCampaignDetailReducer from '../features/assessmentsPlanning/store/auditCampaignDetailSlice';
import assessmentReportResultsReducer from '../features/assessmentsReport/store/assessmentReportResultsSlice';
import brandAuditProposalsReducer from '../features/assessmentsPlanning/store/brandAuditProposalsSlice';
import brandAuditProposalWizardReducer from '../features/assessmentsPlanning/store/brandAuditProposalWizardSlice';
import auditProposalsAgendaReducer from '../features/assessmentsPlanning/store/auditProposalsAgendaSlice';
import brandAuditProposalDetailReducer from '../features/assessmentsPlanning/store/brandAuditProposalDetailSlice';
import assessmentsFollowUpReducer from '../features/assessments/store/assessmentsFollowUpSlice';
import keringRegionsReducer from '../features/users/store/keringRegionsSlice';
import keringCountriesReducer from '../features/users/store/keringCountriesSlice';
import unsavedChangesReducer from './unsavedChangesSlice';
import assessmentBulkUpdateReducer from '../features/assessmentsBulkUpdate/store/assessmentBulkUpdateSlice';

export const logoutAction = createAction<void>('LOGOUT');

const combinedReducer = combineReducers({
  principal: principalReducer,
  assessments: assessmentsReducer,
  assessmentsFilter: assessmentsFilterReducer,
  onCreationAssessment: onCreationAssessmentReducer,
  availableStores: availableStoresReducer,
  auditors: auditorsReducer,
  brandSchedulers: brandSchedulerReducer,
  assessmentDetail: assessmentReducer,
  auditorResults: auditorResultsReducer,
  auditorUpdateRequests: auditorUpdateRequestsReducer,
  users: usersReducer,
  assessmentsArchive: assessmentsArchiveReducer,
  auditCampaigns: auditCampaignsReducer,
  auditProposalWizard: auditProposalWizardReducer,
  auditProposalDetail: auditProposalDetailReducer,
  auditCampaignDetail: auditCampaignDetailReducer,
  assessmentReportResults: assessmentReportResultsReducer,
  brandAuditProposals: brandAuditProposalsReducer,
  brandAuditProposalWizard: brandAuditProposalWizardReducer,
  auditProposalsAgenda: auditProposalsAgendaReducer,
  brandAuditProposalDetail: brandAuditProposalDetailReducer,
  assessmentsFollowUp: assessmentsFollowUpReducer,
  keringRegions: keringRegionsReducer,
  keringCountries: keringCountriesReducer,
  unsavedChanges: unsavedChangesReducer,
  assessmentBulkUpdate: assessmentBulkUpdateReducer,
});

// warning: requests from different users, could be present in the storage
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auditorUpdateRequests'],
  // transforms: []
};

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === 'LOGOUT') {
    deleteJwtToken();
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof combinedReducer>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
