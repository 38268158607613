/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Loader } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { useAppDispatch } from '../../../core/store';
import {
  fetchAssessmentsFollowUp,
  onChangeFilter,
  selectAssessmentsFollowUpFilter,
  selectGroupedAssessmentsFollowUp,
  selectIsFetching,
} from '../store/assessmentsFollowUpSlice';
import { AssessmentCard } from '../components/AssessmentCard';
import { SearchBox } from '../../../shared/SearchBox';
import style from './assessmentsFollowUp.style';
import { FollowUpStatus } from '../model/assessmentDetail.model';
import { getDate } from '../../../core/utils';

export const AssessmentsFollowUp = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isFetching = useSelector(selectIsFetching);
  const groupedAssessment = useSelector(selectGroupedAssessmentsFollowUp);

  useEffect(() => {
    dispatch(fetchAssessmentsFollowUp());
  }, [dispatch]);

  return (
    <div css={style.container}>
      <Header />
      {isFetching ? (
        <Loader active />
      ) : (
        <div css={style.cardsContainer}>
          {Object.entries(groupedAssessment).map(([key, value]) => (
            <div key={key}>
              <h5 css={style.sectionTitle}>
                {t(`assessment.followUpStatus.${key}`)}
                {key === FollowUpStatus.COMPLETED ? ` (${getDate().getFullYear()})` : ''}
              </h5>
              <div css={style.group}>
                {value.map(a => (
                  <AssessmentCard key={a.id} assessment={a} />
                ))}
              </div>
            </div>
          ))}
          {Object.entries(groupedAssessment).length === 0 && <div css={style.notFound}>{t('noItems')}</div>}
        </div>
      )}
    </div>
  );
};

const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const filter = useSelector(selectAssessmentsFollowUpFilter, () => true);
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(onChangeFilter(value));
  }, 500);

  return (
    <div css={style.header}>
      <div css={style.searchBoxContainer}>
        <SearchBox defaultValue={filter} onChange={value => debounced(value)} />
      </div>
    </div>
  );
};
