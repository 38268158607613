/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { emptyRegion, Region } from '../../../../shared/model/region.model';
import { TextInputField } from '../../../assessments/components/TextInputField';
import { DistributionListAssessmentField } from '../../../assessments/components/DistributionListAssessmentField';
import { BrandField } from './BrandField';
import { RegionField } from './RegionField';
import { BrandSchedulersField } from './BrandSchedulersField';
import { Contact, emptyContact } from '../../../users/model/user.model';
import { AuditCampaign, emptyCampaign } from '../../model/auditCampaign';
import { useAppDispatch } from '../../../../core/store';
import { fetchBrands, selectBrands, selectCampaign } from '../../store/auditProposalWizardSlice';
import { Brand, emptyBrand } from '../../../../shared/model/brand.model';
import {
  fetchBrandSchedulers,
  selectBrandSchedulers,
  selectIsFetchingBrandSchedulers,
} from '../../store/brandSchedulersSlice';
import { ButtonConfiguration } from '../WizardFooter';
import { StepSegment } from '../StepSegment';
import { selectIsFetching } from '../../../users/store/usersSlice';
import { BrandScheduler } from '../../model/brandScheduler';
import { fetchKeringRegions, selectKeringRegions } from '../../../users/store/keringRegionsSlice';

interface CampaignCreationStepProps {
  onCampaignSave: (data: AuditCampaign) => void;
  onBack: () => void;
}

export function CampaignCreationStep({ onCampaignSave, onBack }: CampaignCreationStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const initCampaign: AuditCampaign = emptyCampaign();
  const campaign = useSelector(selectCampaign);

  const [campaignName, setCampaignName] = useState(campaign?.name || '');
  const [campaignBrand, setCampaignBrand] = useState<Brand>(campaign?.brand || emptyBrand());
  const [campaignRegion, setCampaignRegion] = useState<Region>(campaign?.region || emptyRegion());
  const [campaignBrandScheduler, setCampaignBrandScheduler] = useState(
    campaign != null && campaign.brandSchedulers.length > 0 ? campaign.brandSchedulers[0] : emptyContact()
  );
  const [campaignDistributionList, setCampaignDistributionList] = useState<Contact[]>(campaign?.distributionList || []);

  const regions: Region[] = useSelector(selectKeringRegions);
  const brands: Brand[] = useSelector(selectBrands);
  const brandSchedulerList: BrandScheduler[] = useSelector(selectBrandSchedulers);
  const isFetchingBrandSchedulers = useSelector(selectIsFetchingBrandSchedulers);
  const isFetching = useSelector(selectIsFetching);

  useEffect(() => {
    dispatch(fetchBrands());
    dispatch(fetchKeringRegions());
  }, [dispatch]);

  useEffect(() => {
    if (campaignBrand.code !== '' && campaignRegion.code !== '') {
      dispatch(fetchBrandSchedulers({ brand: campaignBrand.code, region: campaignRegion.description }));
    }
  }, [campaignBrand, campaignRegion, dispatch]);

  const isValidStep = useMemo(() => {
    return (
      campaignName !== '' &&
      campaignBrand.code !== '' &&
      campaignRegion.code !== '' &&
      campaignBrandScheduler.email !== ''
    );
  }, [campaignName, campaignBrand, campaignRegion, campaignBrandScheduler]);

  const newCampaign: AuditCampaign = useMemo(() => {
    return {
      ...initCampaign,
      name: campaignName,
      brandSchedulers: [campaignBrandScheduler],
      brand: campaignBrand,
      region: campaignRegion,
      distributionList: campaignDistributionList,
    };
  }, [initCampaign, campaignName, campaignBrand, campaignRegion, campaignBrandScheduler, campaignDistributionList]);

  const buttonConfig = useMemo((): ButtonConfiguration[] => {
    return [
      {
        labelPosition: 'left',
        content: t('auditCampaigns.creation.back'),
        icon: 'angle left',
        onClick: onBack,
      },
      {
        labelPosition: 'right',
        disabled: !isValidStep,
        content: t('auditCampaigns.creation.next'),
        icon: 'angle right',
        onClick: () => onCampaignSave(newCampaign),
      },
    ];
  }, [onBack, onCampaignSave, newCampaign, isValidStep]);

  return (
    <StepSegment footerConfiguration={buttonConfig}>
      <div style={{ backgroundColor: 'var(--saa-white-color)' }}>
        <TextInputField
          icon='folder'
          defaultValue={campaignName}
          editable
          title={t('auditCampaigns.creation.name')}
          placeholder='Name'
          label={{ icon: 'folder' }}
          onEdited={value => setCampaignName(value)}
        />
        <BrandField
          brandList={brands}
          loading={isFetching}
          defaultValue={campaignBrand}
          editable
          onSelect={(b: Brand) => setCampaignBrand(b)}
        />
        <RegionField
          regionList={regions}
          loading={isFetching}
          defaultValue={campaignRegion}
          editable
          onSelect={(r: Region) => setCampaignRegion(r)}
        />
        <BrandSchedulersField
          brandSchedulers={brandSchedulerList}
          loading={isFetchingBrandSchedulers}
          defaultValue={campaignBrandScheduler}
          editable={campaignBrand.code !== '' && campaignRegion.code !== ''}
          onSelect={brandScheduler => setCampaignBrandScheduler(brandScheduler)}
          assessmentBrand={campaign?.brand.code}
        />
        <DistributionListAssessmentField
          defaultValue={campaignDistributionList}
          editable
          onEdited={(selectedDistributionList: Contact[]) => {
            setCampaignDistributionList(selectedDistributionList);
          }}
          title={t('auditCampaigns.creation.distributionList') || ''}
        />
      </div>
    </StepSegment>
  );
}
