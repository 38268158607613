import moment from 'moment';
import { Brand, toBrand } from '../../../shared/model/brand.model';
import {
  ACTION_PLAN_STATUS,
  auditInternalControlDescription,
  FollowUpStatus,
} from '../../assessments/model/assessmentDetail.model';
import { AuditScoreRating } from '../../assessments/model/assessment';

export interface AssessmentReportResult {
  status: string;
  brand: Brand;
  region: string;
  country: string;
  city: string;
  hfmCode: string;
  jdaCode: string;
  locationName: string;
  year: number;
  month: number;
  processCode: string;
  processDescription: string;
  subProcessCode: string;
  subProcessDescription: string;
  subProcessResultFindings: string;
  subProcessResultPriority: string;
  subProcessResultRecommendation: string;
  subProcessScore: number;
  failedScorePercentage: number;
  rating: string;
  successScorePercentage: number;
  actionPlanStatus: ACTION_PLAN_STATUS;
  actionPlanDescription: string;
  actionPlanSupervisor: string;
  actionPlanSupervisorRole: string;
  actionPlanDueDate: string | null;
  followUpStatus: string;
  followUpDescription: string;
  followUpResult: string;
  followUpDueDate: string | null;
}

export const toAssessmentReportResults = (a: Record<string, unknown>): AssessmentReportResult[] =>
  (a.processes as Record<string, unknown>[]).flatMap(p =>
    (p.subProcesses as Record<string, unknown>[]).map(sp => toAssessmentReportResult(sp, p, a))
  );

const toAssessmentReportResult = (
  subProcess: Record<string, unknown>,
  process: Record<string, unknown>,
  assessment: Record<string, unknown>
): AssessmentReportResult => {
  const actionPlanDueDateNullValue =
    subProcess.actionPlanStatus === ACTION_PLAN_STATUS.DONE
      ? 'Done'
      : subProcess.actionPlanStatus === ACTION_PLAN_STATUS.NOT_PROGRAMMABLE
      ? 'N/A'
      : null;
  const actionPlanDueDate =
    actionPlanDueDateNullValue != null
      ? actionPlanDueDateNullValue
      : subProcess.actionPlanDueDate != null
      ? moment(subProcess.actionPlanDueDate as string).format('yyyy-MM-DD')
      : actionPlanDueDateNullValue;

  return {
    status: assessment.status as string,
    brand: toBrand(assessment.brand as string),
    region: assessment.region as string,
    country: assessment.country as string,
    city: assessment.city as string,
    hfmCode: assessment.hfmCode as string,
    jdaCode: assessment.jdaCode as string,
    locationName: assessment.locationName as string,
    year: assessment.year as number,
    month: assessment.month as number,
    processCode: process.code as string,
    processDescription: process.description as string,
    subProcessCode: subProcess.code as string,
    subProcessDescription: subProcess.description as string,
    subProcessResultFindings: subProcess.resultFindings as string,
    subProcessResultPriority: subProcess.resultPriority as string,
    subProcessResultRecommendation: subProcess.resultRecommendation as string,
    subProcessScore: subProcess.score as number,
    failedScorePercentage: assessment.failedScorePercentage as number,
    rating: auditInternalControlDescription.get(assessment.rating as AuditScoreRating) as string,
    successScorePercentage: assessment.successScorePercentage as number,
    actionPlanStatus: subProcess.actionPlanStatus as ACTION_PLAN_STATUS,
    actionPlanDescription: subProcess.actionPlanDescription as string,
    actionPlanSupervisor: subProcess.actionPlanSupervisor as string,
    actionPlanSupervisorRole: subProcess.actionPlanSupervisorRole as string,
    actionPlanDueDate,
    followUpStatus:
      assessment.followUpStatus != null ? (assessment.followUpStatus as FollowUpStatus) : FollowUpStatus.EMPTY,
    followUpDescription: subProcess.followUpDescription as string,
    followUpResult: subProcess.followUpResult as string,
    followUpDueDate:
      subProcess.followUpDueDate != null ? moment(subProcess.followUpDueDate as string).format('yyyy-MM-DD') : null,
  };
};
